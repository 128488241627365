.fma-modal.session-modal {
  /* The Modal (background) */
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 101;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */

  //SessionErrorPopUp style
  .fma_mo {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 100;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: black;
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
  }

  .fma_mo_content {
    margin: 15% auto;
    /* centered */
    width: 600px;
    height: 330px;
    position: relative;
    .border-radius-none {
      border-radius: 0;
      padding-left: 10px;
      padding-right: 0;
    }
  }

  .bg-overlay {
    background-color: black;
    opacity: 0.5;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 101;
  }

  .fma-login-mobile {
    display: none !important;
  }

  @media screen and (max-width: 945px) {
    .fma_mo_content {
      width: 90%;
      margin-top: 33%;
    }
  }

  @media screen and (max-width: 710px) {
    .fma_mo_content {
      height: 350px;
    }
    .fma-login {
      display: none !important;
    }
    .fma-login-mobile {
      display: inline !important;
    }
  }

  @media screen and (max-width: 555px) {
    .fma_mo_content {
      height: 460px;
    }
  }

  @media screen and (max-width: 472px) {
    .fma_mo_content {
      height: 480px;
    }
  }

  @media screen and (max-width: 302px) {
    .fma_mo_content {
      height: 540px;
    }
  }

  .fma_description {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 26px;
    margin-bottom: 1em;
  }

  ///

  /* Modal Content/Box */

  .fma-modal-content {
    margin: 15% auto;
    /* centered */
    width: 867px;
    height: 515px;
    position: relative;
  }

  @media screen and (max-width: 945px) {
    .fma-modal-content {
      width: 90%;
      margin-top: 33%;
    }
  }

  @media screen and (max-width: 710px) {
    .fma-modal-content {
      height: 350px;
    }
  }

  @media screen and (max-width: 555px) {
    .fma-modal-content {
      height: 460px;
    }
  }

  @media screen and (max-width: 472px) {
    .fma-modal-content {
      height: 480px;
    }
  }

  @media screen and (max-width: 302px) {
    .fma-modal-content {
      height: 540px;
    }
  }

  /* The Close Button */

  .fma-close {
    font-weight: 200;
    font-size: 48px;
    position: absolute;
    top: 8px;
    right: 24px;
    color: #fff;
    z-index: 1;
  }

  @media screen and (max-width: 945px) {
    .fma-close {
      font-size: 24px;
      right: 12px;
    }
  }

  .fma-close:hover,
  .fma-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .fma-popup_image {
    position: relative;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    object-fit: cover;
  }

  .fma-popup-content {
    position: absolute;
    top: 0%;
    width: 100%;
    height: 100%;
    padding: 4em;
    text-align: center;
    color: #fff;
    // background-color: #000000ad;
    background-color: #324047b8;
  }

  @media screen and (max-width: 945px) {
    .fma-popup-content {
      padding: 2em 1em;
    }
  }

  .fma-header {
    font-size: 24px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 3px;
    line-height: 34px;
    text-transform: uppercase;
    margin-bottom: 1em;
  }

  .fma-header span {
    font-weight: 500;
  }

  .fma-description {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 26px;
    margin-bottom: 1em;
  }

  @media screen and (max-width: 710px) {
    .fma-description {
      display: none;
    }
  }

  .fma-description ~ .fma-description {
    display: none;
    font-size: 14px;
    letter-spacing: 0.88px;
  }

  .fma-description ~ .fma-description br {
    margin: 1em;
  }

  @media screen and (max-width: 710px) {
    .fma-description ~ .fma-description {
      display: block;
    }
  }

  .fma-info {
    font-size: 14px;
    font-weight: 200;
    font-style: normal;
    letter-spacing: 0.88px;
    line-height: 26px;
    margin-bottom: 4em;
  }

  .fma-info span {
    text-decoration: underline;
  }

  @media screen and (max-width: 710px) {
    .fma-info {
      display: none;
      margin-bottom: 1em;
    }
  }

  .fma-login {
    background-color: #fff;
    color: #102b4e;
  }

  .fma-login:hover {
    background-color: #324047 !important;
    color: #fff !important;
  }

  @media screen and (max-width: 710px) {
    .fma-login {
      display: none;
    }
  }

  .fma-login-mobile {
    display: none;
    margin-bottom: 1em;
  }

  @media screen and (max-width: 710px) {
    .fma-login-mobile {
      display: inline;
    }
  }

  .fma-read-more {
    display: none;
    text-decoration: underline;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 24px;
    color: #fff;
  }

  @media screen and (max-width: 710px) {
    .fma-read-more {
      display: inline;
    }
  }

  .fma-open {
    height: 692px;
  }

  @media screen and (max-width: 400px) {
    .fma-open {
      height: 790px;
    }
  }

  @media screen and (max-width: 374px) {
    .fma-open {
      height: 970px;
    }
  }

  @media screen and (max-width: 296px) {
    .fma-open {
      height: 90vh;
    }
  }

  .fma-hide {
    display: none;
  }

  .fedex-validate-modal {
    padding: 25px;
    width: 550px;
    margin: 0 auto;
    background: #fff;
    font-family: sans-serif;
    .modal-title {
      margin: 0 0 15px 0;
      font-family: sans-serif;
      font-size: 3rem;
    }
    .fedex-error-msg {
      color: red;
      font-size: 1rem;
      margin: 0 0 15px 0;
    }
    .fedex-address-wrap {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
      .addressbox {
        // flex-basis: 50%;
        // max-width:50%;
        // flex-grow: 0;
        min-width: 50%;
        margin-bottom: 15px;
        p {
          margin: 5px 0;
        }
        .MuiButton-containedPrimary {
          margin-top: 15px;
        }
      }
      .enteredaddress {
        padding-right: 15px;
      }
    }
  }
}
