.lincoln-menu {
  position: relative;
  z-index: 500;
  .expanded-menu-container {
    background-color: inherit !important;
    color: #324047 !important;
  }
  .expanded-menu-text {
    color: #324047 !important;
    text-transform: uppercase;
    font-size: 12px !important;
    letter-spacing: 1.5px;
    font-family: var(--fmc-font--proxima-nova);
  }
  .expanded-menu-hover {
    background-color: inherit !important;
    color: #324047 !important;
  }
  .expanded-menu-sub-links {
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}
@media (min-width: 1200px) {
  .searchbar-global .MuiFormControl-root {
    width: 100% !important;
  }
}
.expanded-menu-paper {
  width: 600px !important;
  border: 1px solid #bbbbbb;
}
.megamenu__list {
  border-top: 1px solid #bbbbbb;
  padding-top: 20px;
  display: block !important;
}

.expanded-menu-three-tier-submenu {
  width: 620px !important;
  border: 1px solid #bbbbbb;
}
.subcategory-list {
  margin-top: 10px;
  margin-right: 0px;
}
.expanded-menu button,
.expanded-menu-hover button {
  border-radius: 0px !important;
  padding: 0px 5px 29px 5px !important;
  margin: 31px 11px 0px 11px;
  border-bottom: 3px solid transparent;
  @media (min-width:959px) and (max-width:1024px) {
    padding: 0px 5px 19px 5px !important;
     
  }
}
.main-menu:hover .expanded-menu-hover button {
  border-bottom: 3px solid #f26148;
}
.category-title {
  font-weight: 600;
  color: #324047;
  text-transform: uppercase;
  font-size: 13px;
  font-family: var(--fmc-font--proxima-nova);
  letter-spacing: 1.5px;
}
.accordian-inner p {
  font-weight: 500;
  color: #324047;
  font-size: 12px;
  font-family: var(--fmc-font--proxima-nova);
  letter-spacing: 1px;
  span {
    font-size: 12px;
    font-weight: 300;
  }
}
.closemenu-button {
  position: absolute;
  top: 10px;
  right: 15px;
  svg {
    color: #324047;
    width: 1.5em;
    height: 1.5em;
  }
}

.searchbar-global {
  background: #ffffff;
}
.thumbnail-product {
  width: 250px;
  height: 250px;
  display: inline-block;
}
#global-search {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 26px;
  color: #324047;
  width: 100%;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.minicarttxt {
  font-family: var(--fmc-font--proxima-nova);
  font-weight: 400;
  font-size: 10px;
  text-transform: uppercase;
}
.minicartcontainer {
  margin-right: 20px;
  @media (min-width:275px) and (max-width:360px) {
    margin-right: 8px;
  }
}
.minicartmobile {
  padding-left: 0px !important;
  margin-top: 7px;
}

.expanded-menu-three-tier-submenu {
  overflow-y: scroll !important;
  height: 450px;
}
.user-menu__item--account,
.user-menu__item--garage {
  a:hover {
    color: #4d4d4d !important;
  }
}
@media (max-width: 390px) {
  .header-branding .site-header__logo,
  .header-branding .site-header__logo svg {
    margin-right: 15px;
    width: 60px !important;
  }
}

@media (min-width:275px) and (max-width: 360px) {
  .user-menu__item--garage {
      width: 36px !important; 
  }
  .user-menu__item--garage-no-chevron { 
    margin-left:5px !important;
  }
  .site-header .MuiContainer-maxWidthLg {
    padding-left: 8px;
    padding-right: 8px;
  }
  .site-header__user-menu [class*=user-menu__item] svg { 
    width: 21px !important;
 }
  
  .site-header__user-menu [class*=user-menu__item]:nth-of-type(n + 2) {
      margin-left: 0px !important;
  }


}
@media (min-width:275px) and (max-width: 300px) { 
  .header-actionsButton {
    padding: 5px !important;
  }
  .user-menu__item--garage {
    width: 30px !important; 
}
.site-header .MuiContainer-maxWidthLg {
  padding-left: 6px;
  padding-right: 6px;
}
}
