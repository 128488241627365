@mixin modal-wrap {
  .print_wrap {
    display: flex;
    justify-content: center;
    
    .slip_wrap {
      display: flex;
      flex-direction: column;
      border: 2px solid var(--fds-color--primary);
      padding: 1em;
      width: 85%;
      margin-top: 1em;

      p {
        color: black;
        text-align: center;
        padding: 0;
        margin: 0;
        font-size: 1.2em;
      }

      hr {
        width: 100%;
        color: var(--fds-color--primary);
        border-top: 2px solid;
        opacity: 1;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1em 0em 1em 0em;

        .logo {
          img {
            height: 4em;
          }
        }

        a {
          font-size: 1.3em;
          font-weight: bold;
          @media(max-width: 767px) {
            font-size:11px;
          }
        }
        a:hover{
          color:#f26148;
        }
      }
      .title {
        font-size: 2.3em;
        font-weight: bold;
        @media(max-width: 767px) {
          font-size:20px;
        }
      }
      .num {
        display: flex;
        padding: 2em 0em 1em 0em;
        flex-direction: column;
        align-items: flex-start;

        p {
          flex: 1;
          text-align: left;

          :first-child {
            color: var(--fds-color--primary);
            margin-right: 1em;
          }
        }
      }

      .part_num {
        display: flex;
        padding: 1em 0em 1em 0em;

        p:first-child {
          color: var(--fds-color--primary);
          margin-right: 1em;
        }
      }

      .atten {
        text-align: left;
        padding: 1em 0em 1em 0em;

        :first-child {
          font-weight: bold;
        }
      }

      .ins {
        display: flex;
        flex-direction: column;
        padding: 1em 0em 1em 0em;

        p {
          text-align: left;
        }

        p:first-child {
          color: var(--fds-color--primary);
          font-weight: bold;
        }

        :nth-child(2) {
          display: flex;
          flex-direction: column;
        }
      }

      .quries {
        padding: 1em 0em 1em 0em;
      }

      .return_notice_wrap {
        padding: 1em 0em 1em 0em;
        color: var(--fds-color--primary);
        border: 1px solid var(--fds-color--primary);

        p:first-child {
          font-weight: bold;
        }
      }

      .cmp {
        padding: 1em 0em 1em 0em;
        display: flex;
        flex-direction: column;

        :first-child {
          color: var(--fds-color--primary);
          font-weight: bold;
        }
      }
    }
  }
}

#ford_fulfill_label_popup {
  .modal_wrap {
    @include modal-wrap();
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1em;

    .action-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top:8px;
    }
    .cancelbutton-package {
      border: 1px solid #324047;
      padding: 13px;
      padding-left: 20px;
      padding-right: 20px;
    }
    .cancelbutton-package:hover{
      color:#f26148;
    }
  }
  .fmc-dialog__content {
    max-height: calc(100vh - 190px);
    overflow: auto;
  }
  
}

#orderLabel .state-popup__inner.flex.f-dir-column {margin-top: 60px;}
@media (max-width: 768px) {
  #orderLabel li.return-details-items__content-product {
    display:initial;
  }
  #orderLabel .return-details-items__content-product-total {
    display: flex;
  }
  #orderLabel .return-details-items__content-product .return-details-items__content-product-info .order__details a {
    display: initial;
  }
}
.print_wrap .slip_wrap .ins p, .print_wrap .slip_wrap .ins span {
  font-size:16px;
}
.print_wrap .slip_wrap {
  .atten, .part_num p, .quries, p.cmp {
    font-size:17px !important;
  }
}
.print_wrap .slip_wrap .part_num p {
  text-align:left !important;
}
.state-popup__inner.flex.f-dir-column {
  padding-top: 30px;
}
.state-popup__close {
  font-size: 30px;
  font-weight: 400;
  position: absolute;
    right: 30px;
    /* float: right; */
    top: 8px;
}

@media print {
  #ford_fulfill_label_popup {
    .modal_wrap {
      @include modal-wrap();

      .action-wrap {
        display: none;
      }
    }
  }
}