 
.CheckOutPage, .OrderConfirmationPage { 

@-webkit-keyframes fade-in {
  0% {
      opacity: 0;
      visibility: hidden
  }

  100% {
      opacity: 1;
      visibility: visible
  }
}

@keyframes fade-in {
  0% {
      opacity: 0;
      visibility: hidden
  }

  100% {
      opacity: 1;
      visibility: visible
  }
}

@-webkit-keyframes fade-out {
  0% {
      opacity: 1;
      visibility: visible
  }

  100% {
      opacity: 0;
      visibility: hidden
  }
}

@keyframes fade-out {
  0% {
      opacity: 1;
      visibility: visible
  }

  100% {
      opacity: 0;
      visibility: hidden
  }
}

@-webkit-keyframes rotate {
  0% {
      -webkit-transform: rotate(0);
      transform: rotate(0)
  }

  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
  }
}

@keyframes rotate {
  0% {
      -webkit-transform: rotate(0);
      transform: rotate(0)
  }

  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
  }
}

@-webkit-keyframes draw-stroke {
  100% {
      stroke-dashoffset: 0
  }
}

@keyframes draw-stroke {
  100% {
      stroke-dashoffset: 0
  }
}

@-webkit-keyframes checkmark-fill {
  100% {
      -webkit-box-shadow: inset 0px 0px 0px 30px #2861a4;
      box-shadow: inset 0px 0px 0px 30px #2861a4
  }
}

@keyframes checkmark-fill {
  100% {
      -webkit-box-shadow: inset 0px 0px 0px 30px #2861a4;
      box-shadow: inset 0px 0px 0px 30px #2861a4
  }
}

@-webkit-keyframes scale-up-bounce {
  0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
      transform: scale(0.5)
  }

  40% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1)
  }

  100% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@keyframes scale-up-bounce {
  0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
      transform: scale(0.5)
  }

  40% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1)
  }

  100% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@-webkit-keyframes scale-up-bounce-spring {
  0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
      transform: scale(0.5)
  }

  20% {
      -webkit-transform: scale(1.15);
      transform: scale(1.15)
  }

  50% {
      opacity: 1;
      -webkit-transform: scale(0.95);
      transform: scale(0.95)
  }

  100% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@keyframes scale-up-bounce-spring {
  0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
      transform: scale(0.5)
  }

  20% {
      -webkit-transform: scale(1.15);
      transform: scale(1.15)
  }

  50% {
      opacity: 1;
      -webkit-transform: scale(0.95);
      transform: scale(0.95)
  }

  100% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@-webkit-keyframes scale-down-bounce {
  0% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1)
  }

  20% {
      -webkit-transform: scale(1.05);
      transform: scale(1.05)
  }

  100% {
      opacity: 0;
      -webkit-transform: scale(0.5);
      transform: scale(0.5)
  }
}

@keyframes scale-down-bounce {
  0% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1)
  }

  20% {
      -webkit-transform: scale(1.05);
      transform: scale(1.05)
  }

  100% {
      opacity: 0;
      -webkit-transform: scale(0.5);
      transform: scale(0.5)
  }
}

@-webkit-keyframes scale-up {
  0% {
      -webkit-transform: scale(0);
      transform: scale(0)
  }

  100% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@keyframes scale-up {
  0% {
      -webkit-transform: scale(0);
      transform: scale(0)
  }

  100% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@-webkit-keyframes pulse {
  0% {
      -webkit-box-shadow: 0 0 0 0 rgba(40, 97, 164, 0.5);
      box-shadow: 0 0 0 0 rgba(40, 97, 164, 0.5)
  }

  50% {
      -webkit-box-shadow: 0 0 0 15px rgba(40, 97, 164, 0);
      box-shadow: 0 0 0 15px rgba(40, 97, 164, 0)
  }
}

@keyframes pulse {
  0% {
      -webkit-box-shadow: 0 0 0 0 rgba(40, 97, 164, 0.5);
      box-shadow: 0 0 0 0 rgba(40, 97, 164, 0.5)
  }

  50% {
      -webkit-box-shadow: 0 0 0 15px rgba(40, 97, 164, 0);
      box-shadow: 0 0 0 15px rgba(40, 97, 164, 0)
  }
}

@-webkit-keyframes wobble {

  0%,
  100% {
      -webkit-transform: rotate(10deg);
      transform: rotate(10deg)
  }

  50% {
      -webkit-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }
}

@keyframes wobble {

  0%,
  100% {
      -webkit-transform: rotate(10deg);
      transform: rotate(10deg)
  }

  50% {
      -webkit-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }
}

@-webkit-keyframes modal-open {
  0% {
      opacity: 0;
      -webkit-transform: translateY(20%);
      transform: translateY(20%)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
      visibility: visible
  }
}

@keyframes modal-open {
  0% {
      opacity: 0;
      -webkit-transform: translateY(20%);
      transform: translateY(20%)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
      visibility: visible
  }
}

@-webkit-keyframes modal-close {
  0% {
      opacity: 1;
      visibility: visible
  }

  100% {
      opacity: 0;
      -webkit-transform: translateY(20%);
      transform: translateY(20%)
  }
}

@keyframes modal-close {
  0% {
      opacity: 1;
      visibility: visible
  }

  100% {
      opacity: 0;
      -webkit-transform: translateY(20%);
      transform: translateY(20%)
  }
}

@media (max-width: 999px) {
  .quirks-fix--ios-instagram {
      height: auto;
      min-height: 100%;
      padding-bottom: 250px
  }
}

html,
body {
  margin: 0;
  width: 100%;
  height: 100%
}

html {
  font-family: var(--fmc-font--proxima-nova);
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

body {
  overflow-x: hidden
}

iframe {
  margin: 0;
  padding: 0;
  border: 0
}

b,
strong {
  font-weight: 500
}

small {
  font-size: .875em
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
  line-height: 1em
}

p {
  margin: 0
}
p.Earn-Content-2 {
  font-size:1em;
}
p.Earn-Content-1 {
  font-size:1.2em !important;
}
sub,
sup {
  font-size: .75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sup {
  top: -0.5em
}

sub {
  bottom: -0.25em
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none
}

img {
  border: 0;
  max-width: 100%
}

figure {
  margin: 0
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0
}

form {
  margin: 0
}

/*button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-font-smoothing: inherit;
  border: none;
  background: transparent;
  line-height: normal
}*/

button {
  overflow: visible
}

button,
select {
  text-transform: none
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  cursor: pointer
}

button:-moz-focusring,
input[type="button"]:-moz-focusring,
input[type="reset"]:-moz-focusring,
input[type="submit"]:-moz-focusring {
  outline: 1px dotted
}

button[disabled],
input[disabled] {
  cursor: default
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto
}

input[type="search"] {
  -webkit-box-sizing: content-box;
  box-sizing: content-box
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  border: 0;
  padding: 0
}

textarea {
  overflow: auto
}

select::-ms-expand {
  display: none
}

optgroup {
  font-weight: bold
}

option {
  background-color: #fff;
  color: #000
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em
}

td,
th {
  padding: 0;
  font-weight: normal
}

.text-container>*+* {
  margin-top: 0.5714285714em
}

.text-container *+.heading-1,
.text-container *+.heading-2,
.text-container *+.heading-3 {
  margin-top: 1.4285714286em
}

a,
.link {
  text-decoration: none;
  color: #2861a4;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;

  &__content {
      display: -moz-inline-flex;
      display: inline-flex;
      -moz-align-items: center;
      align-items: center;
      -moz-justify-content: center;
      justify-content: center;
      font-size: 16px;

      svg {
          fill: #FFF;
      }

      &:hover {
          svg {
              fill: #102b4e;
          }
      }
  }
}

a:hover,
.link:hover {
  color: #215087;
}

.link--muted {
  color: inherit;
  text-decoration: underline
}

h1,
.heading-1 {
  font-size: 1.7142857143em;
  line-height: 1.3em
}

@media (min-width: 750px) {

  h1,
  .heading-1 {
      font-size: 2em
  }
}

h2,
.heading-2 {
  font-size: 1.2857142857em;
  line-height: 1.3em
}

.main h2,
.main .heading-2 {
  color: #333333
}

.sidebar h2,
.sidebar .heading-2 {
  color: white
}

.content-box h2,
.content-box .heading-2 {
  color: #333333
}

.default-background h2,
.default-background .heading-2 {
  color: #333333
}

h3,
.heading-3 {
  font-family: var(--fmc-font--proxima-nova);
  font-size: 1em;
  font-weight: 600;
  line-height: 1.3em
}

.main h3,
.main .heading-3 {
  color: #333333
}

.sidebar h3,
.sidebar .heading-3 {
  color: white
}

.content-box h3,
.content-box .heading-3 {
  color: #333333
}

.default-background h3,
.default-background .heading-3 {
  color: #333333
}

.emphasis {
  font-weight: 600
}

.main .emphasis {
  color: #333333
}

.sidebar .emphasis {
  color: white
}

.content-box .emphasis {
  color: #333333
}

.default-background .emphasis {
  color: #333333
}

.main .small-text .emphasis {
  color: #545454
}

.sidebar .small-text .emphasis {
  color: #cfd5dc
}

.content-box .small-text .emphasis {
  color: #545454
}

.default-background .small-text .emphasis {
  color: #545454
}

.small-text {
  font-size: 0.8571428571em
}

.main .small-text {
  color: #737373
}

.sidebar .small-text {
  color: #a0aab8
}

.content-box .small-text {
  color: #737373
}

.default-background .small-text {
  color: #737373
}

.address {
  font-style: normal;
  line-height: 1.5em
}

.address--tight {
  line-height: inherit
}

.pickup-instructions {
  width: 200px;
  white-space: initial
}

@media (max-width: 749px) {
  .hide-on-mobile {
      display: none
  }
}

@media (min-width: 750px) {
  .show-on-mobile {
      display: none
  }
}

.layout-flex {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: -0.2857142857rem
}

.layout-flex--wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.layout-flex--tight-vertical {
  margin-bottom: -0.1428571429rem;
  margin-top: -0.1428571429rem
}

.layout-flex--tight-vertical .layout-flex__item {
  padding-bottom: 0.1428571429rem;
  padding-top: 0.1428571429rem
}

.layout-flex--loose-horizontal {
  margin-left: -0.5714285714rem;
  margin-right: -0.5714285714rem
}

.layout-flex--loose-horizontal .layout-flex__item {
  padding-left: 0.28rem;
  padding-right: 0.5714285714rem
}

.layout-flex__item {
  padding: 0.1428571429rem
}

.layout-flex__item--stretch {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

.icon-svg {
  display: inline-block;
  vertical-align: middle;
  fill: currentColor
}

.icon-symbols {
  display: none
}

.icon-svg--block {
  display: block
}

.icon-svg--color-accent {
  color: #2861a4;
  fill: currentColor
}

.icon-svg--color-black {
  color: #000;
  fill: currentColor
}

.icon-svg--color-blue {
  color: #1990c6;
  fill: currentColor
}

.icon-svg--color-gray {
  color: #9b9b9b;
  fill: currentColor
}

.icon-svg--color-gray-dark {
  color: #595959;
  fill: currentColor
}

.icon-svg--color-gray-light {
  color: #959595;
  fill: currentColor
}

.icon-svg--color-red {
  color: #ce4549;
  fill: currentColor
}

.icon-svg--color-white {
  color: #fff;
  fill: currentColor
}

.icon-svg--color-yellow {
  color: #e7a321;
  fill: currentColor
}

.main .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor
}

.sidebar .icon-svg--color-adaptive-lighter {
  color: #a0aab8;
  fill: currentColor
}

.content-box .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor
}

.default-background .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor
}

.btn .icon-svg--color-adaptive-lighter,
.sp-modal-toggle .icon-svg--color-adaptive-lighter {
  color: #a0aab8;
  fill: currentColor
}

.main .field__icon .icon-svg--color-adaptive-lighter,
.main .field__caret .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor
}

.sidebar .field__icon .icon-svg--color-adaptive-lighter,
.sidebar .field__caret .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor
}

.content-box .field__icon .icon-svg--color-adaptive-lighter,
.content-box .field__caret .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor
}

.default-background .field__icon .icon-svg--color-adaptive-lighter,
.default-background .field__caret .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor
}

.main .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor
}

.sidebar .icon-svg--color-adaptive-light {
  color: #a0aab8;
  fill: currentColor
}

.content-box .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor
}

.default-background .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor
}

.btn .icon-svg--color-adaptive-light,
.sp-modal-toggle .icon-svg--color-adaptive-light {
  color: #a0aab8;
  fill: currentColor
}

.main .field__icon .icon-svg--color-adaptive-light,
.main .field__caret .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor
}

.sidebar .field__icon .icon-svg--color-adaptive-light,
.sidebar .field__caret .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor
}

.content-box .field__icon .icon-svg--color-adaptive-light,
.content-box .field__caret .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor
}

.default-background .field__icon .icon-svg--color-adaptive-light,
.default-background .field__caret .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor
}

.icon-svg--size-10 {
  width: 10px;
  height: 10px
}

.icon-svg--size-12 {
  width: 12px;
  height: 12px
}

.icon-svg--size-14 {
  width: 14px;
  height: 14px
}

.icon-svg--size-16 {
  width: 16px;
  height: 16px
}

.icon-svg--size-18 {
  width: 18px;
  height: 18px
}

.icon-svg--size-24 {
  width: 24px;
  height: 24px
}

.icon-svg--size-32 {
  width: 32px;
  height: 32px
}

.icon-svg--size-48 {
  width: 48px;
  height: 48px
}

.icon-svg--size-64 {
  width: 64px;
  height: 64px
}

.icon-svg--size-184 {
  width: 184px;
  height: 184px
}

.icon-svg--rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.icon-svg--inline-before {
  margin-right: 0.2857142857em
}

.icon-svg--inline-after {
  margin-left: 0.2857142857em
}

.icon-svg--flip-horizontal {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1)
}

.icon-svg--flip-vertical {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1)
}

.icon {
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block
}

.icon-background--payment-gateways {
  fill: #d9d9d9
}

.icon-svg--spinner-button {
  width: 20px;
  height: 20px
}

.icon--shopify-pay-primary {
  width: 55px;
  height: 16px
}

.icon--shopify-pay {
  width: 59px;
  height: 14px
}

.icon--shopify-pay--dark {
  fill: #000
}

.icon--shopify-pay--light {
  fill: #fff
}

.icon-svg--re-order {
  width: 20px;
  height: 16px
}

.icon-svg--spinner {
  -webkit-animation: fade-in 0.5s ease-in-out, rotate 0.5s linear infinite;
  animation: fade-in 0.5s ease-in-out, rotate 0.5s linear infinite
}

.icon-svg--clickable {
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out
}

.icon-svg--clickable:hover {
  color: #215087
}

.icon-svg--center {
  position: absolute;
  left: 50%;
  right: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.logo {
  display: block;
  vertical-align: middle
}

.logo__text {
  color: #333333
}

.logo__image {
  display: inline-block
}

.logo--right {
  text-align: right
}

.logo--center {
  text-align: center
}

.logo__image--small {
  max-height: 2.1428571429em
}

.logo__image--medium {
  max-height: 2.8571428571em
}

.logo__image--large {
  max-height: 3.5714285714em
}

@media (min-width: 1000px) {
  .logo__image--small {
      max-height: 2.8571428571em
  }

  .logo__image--medium {
      max-height: 4.2857142857em
  }

  .logo__image--large {
      max-height: 5.7142857143em
  }
}

body {
  color: #545454;
  background: white
}

.content {
  overflow: hidden
}

.wrap {
  display: block;
  margin: 0 auto;
 /* max-width: 40em;*/
  zoom: 1
}

.wrap:after,
.wrap:before {
  content: "";
  display: table
}

.wrap:after {
  clear: both
}

@media (max-width: 999px) {
  .wrap {
      width: 100%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0 1em
  }
}

@media (min-width: 959px) {
  .wrap {
      padding: 0 5%;
      width: 90%;
      max-width: 78.5714285714em;

      &.wrap__thankYou {
          max-width: 95em;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -webkit-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          display: flex;
          width: 100%;
          padding: 0;
          .product__price {
            /*  padding-right: 16px;*/
          }
      }
  }
}

@media (max-width: 999px) {
  .no-js .content .wrap {
      display: table
  }

  .no-js .content .wrap .main {
      display: table-footer-group
  }

  .no-js .content .wrap .sidebar {
      display: table-header-group
  }

  .no-js .content .wrap .sidebar::after {
      display: none
  }
}

@media (min-width: 1000px) { 
  .main {
      width: 52%;
      padding-right: 6%;
      float: left
  }
  
}

.main .logo {
  display: none
}

@media (min-width: 1000px) {
  .page--logo-main .main .logo {
      display: block
  }
}

.main__emphasis {
  font-weight: 600;
  color: #333333
}

.main__small-text {
  font-size: 0.8571428571em;
  color: #737373
}

.sidebar {
  position: relative;
  color: #cfd5dc
}

.no-js .sidebar,
.no-generatedcontent .sidebar {
  background: #112b4e
}

@media (min-width: 1000px) {
  .sidebar {
      width: 100%;
      padding-left: 4%;
      background-position: left top;
      float: right;

      &.cartReview {
          width: 50%;
          padding-top: 4em;
      }
  }
}

.sidebar::after {
  content: "";
  display: block;
  width: 300%;
  position: absolute;
  top: 0;
  left: -100%;
  bottom: 0;
  background: rgb(35, 31, 32);
  z-index: -1
}

@media (min-width: 1000px) {
  .sidebar::after {
      left: 0;
      background-position: left top
  }
}

.sidebar__header {
  margin-bottom: 2.5em;
  display: none
}

@media (min-width: 1000px) {
  .page--logo-sidebar .sidebar__header {
      display: block
  }
}

.anyflexbox body,
.anyflexbox .content,
.anyflexbox .content .wrap,
.anyflexbox .main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto
}

.anyflexbox .content .wrap {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse
}

.anyflexbox .main__content {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto
}

.anyflexbox .banner {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0
}

@media (min-width: 1000px) {
  .anyflexbox .content .wrap {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row
  }
}

.page--hidden-breadcrumbs .breadcrumb,
.page--hidden-main-header .main__header {
  display: none
}

.main {
  &.order-confirmation-main {
      main {
          margin-top: 0;
      }
  }
}

@media (max-width: 749px) {
  .main {
      padding-top: 1.5em
  }

  .main__content {
      padding-bottom: 1.5em
  }
}

@media (min-width: 750px) {
  .main {
      padding-top: 1.5em;

      &.order-confirmation-main {
          padding-top: 2.5em;
          text-align: left;
      }
  }

  .main__content {
      padding-bottom: 4em;
  }
}

@media (max-width: 999px) {
  .breadcrumb {
      padding-bottom: 1.5em
  }
}

@media (min-width: 1000px) {

  .page--no-banner .main,
  .page--no-banner .sidebar {
      padding-top: 4em
  }

  .page--banner .main,
  .page--banner .sidebar {
      padding-top: 2em
  }

  .main__header {
      padding-bottom: 2em
  }

  .sidebar__logo {
      margin-bottom: 2em
  }

  .page--logo-main .breadcrumb {
      margin-top: 1em
  }
}

table {
  width: 100%
}

td,
th {
  padding-left: 1em
}

td:first-child,
th:first-child {
  padding-left: 0;
  text-align: left
}

td:last-child,
th:last-child {
  text-align: right
}

.product-table th,
.product-table td {
  padding-top: 0;
  padding-bottom: 0
}

.product-table__header th {
  white-space: nowrap;
  font-weight: 600;
  color: #333333
}

.product-table--loose th,
.product-table--loose td {
  padding-top: 1.1428571429em;
  padding-bottom: 1.1428571429em
}

.product-table--extra-loose th,
.product-table--extra-loose td {
  padding-top: 1.7142857143em;
  padding-bottom: 1.7142857143em
}

.product-table--bordered td,
.product-table--bordered th {
  border-bottom: 1px solid #e6e6e6
}

.product-table--bordered tr:last-child td {
  border-bottom: 0
}

.banner {
  padding: 1.5em 0;
  background: transparent;
  background-size: cover;
  display: none
}

.page--logo-sidebar .banner {
  background: transparent
}

@media (max-width: 999px) {
  .banner {
      display: block
  }
}

.page--banner .banner {
  display: block
}

@media (min-width: 1000px) {
  .page--banner .banner {
      padding-top: 6em;
      padding-bottom: 2em
  }
}

.btn,
.sp-modal-toggle {
  cursor: pointer;
  display: inline-block;
  background-color: #112b4e;
  background-clip: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px transparent solid;
  /*border-radius: 20px;*/
  font-weight: 500;
  padding-top: 0;
  padding-bottom: 0;
  color: white;
  text-align: center;
  position: relative;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out
}
.order-confirmation-main .step__footer__continue-btn.btn {
  /*padding: 11px 40px 10px 40px;*/
  background-color: #324047;
  padding-top:4px;
}
.order-confirmation-main .step__footer__continue-btn.btn:after {
  position: relative;
  right: -2px;
  top: 3px;
}
.main__content .section__header .print-button:hover {
  border-bottom: 3px solid #f26148;
}
.btn:hover,
.sp-modal-toggle:hover {
  background-color: #081424;
  color: white
}

.btn:active,
.sp-modal-toggle:active {
  outline: none
}

@media (max-width: 749px) {

  .btn,
  .sp-modal-toggle {
      width: 100%;
      padding-top: 1.75em;
      padding-bottom: 1.75em
  }
}

.btn--size-small {
  padding: 0.9285714286em
}

.btn--subdued {
  background-color: transparent;
  border-color: rgba(179, 179, 179, 0.5);
  color: #2861a4;
  font-weight: normal
}

.btn--subdued:hover {
  background-color: rgba(40, 97, 164, 0.06);
  color: #215087
}

.btn--subdued:focus,
.btn--subdued:active {
  border-color: #081424;
  -webkit-box-shadow: 0 0 0 1px #081424 inset;
  box-shadow: 0 0 0 1px #081424 inset
}

.btn--loading {
  pointer-events: none;
  cursor: default
}

.btn--loading .btn__content,
.btn--loading .btn__icon {
  opacity: 0
}

.btn--loading .btn__spinner {
  -webkit-animation: rotate 0.5s linear infinite;
  animation: rotate 0.5s linear infinite;
  opacity: 1
}

.js .main .btn--disabled {
  cursor: default;
  background: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none
}

.js .sidebar .btn--disabled {
  cursor: default;
  background: #4d607a;
  -webkit-box-shadow: none;
  box-shadow: none
}

.js .content-box .btn--disabled {
  cursor: default;
  background: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none
}

.js .default-background .btn--disabled {
  cursor: default;
  background: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none
}

.btn__content {
  position: relative;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out
}

.btn__content--subtitle {
  font-size: 0.8571428571em;
  color: #737373
}

.button-group__button--active .btn__content--subtitle {
  color: #2861a4
}

.btn__icon {
  margin-top: -0.2857142857em;
  margin-bottom: -0.0714285714em
}

.btn__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  opacity: 0
}

.step__footer {
  z-index: 2;
  position: relative;
  margin-top: 1em
}

.no-js .step__footer,
.no-anyflexbox .step__footer {
  zoom: 1
}

.no-js .step__footer:after,
.no-js .step__footer:before,
.no-anyflexbox .step__footer:after,
.no-anyflexbox .step__footer:before {
  content: "";
  display: table
}

.no-js .step__footer:after,
.no-anyflexbox .step__footer:after {
  clear: both
}

@media (min-width: 750px) {
  .step__footer {
      margin-top: 1.5em
  }
}

.step__footer__previous-link-content {
  vertical-align: middle font-size 18px;
}

.step__footer__previous-link,
.step__footer__info {
  display: block;
  font-weight: 500;
    color: #324047;
    svg {
      font-weight: 500;
      color: #324047;
    }
}

@media (max-width: 749px) {

  .step__footer__previous-link,
  .step__footer__info {
      padding-top: 1.5em;
      text-align: center;
  }
}

@media (min-width: 750px) {

  .step__footer__previous-link,
  .step__footer__info { 
      float: left
  }
}

.previous-link__icon {
  -webkit-transition: all 0.2s cubic-bezier(0.3, 0, 0, 1);
  transition: all 0.2s cubic-bezier(0.3, 0, 0, 1);
  margin-right: 0.25em
}

.step__footer__previous-link:hover .previous-link__icon {
  fill: #215087;
  -webkit-transform: translateX(-5px) "";
  transform: translateX(-5px) ""
}

@media (min-width: 750px) {
  .step__footer__continue-btn {
      float: right
  }
}

.step__footer__continue-btn-text {
  clear: both;
  padding-top: 0.75em
}

@media (max-width: 999px) {
  .step__footer__continue-btn-text {
      text-align: center
  }
}

@media (min-width: 750px) {
  .anyflexbox .step__footer {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between
  }

  .anyflexbox .step__footer__previous-link,
  .anyflexbox .step__footer__info {
      margin-right: 1em
  }
}

.edit-link {
  display: block;
  margin-top: 0.75em
}

.section--express-checkout-selection-group-test {
  display: table;
  width: 100%
}

@media (min-width: 750px) {
  .section--express-checkout-selection-group-test .section--contact-information {
      display: table-header-group
  }
}

@media (min-width: 750px) {
  .section--express-checkout-selection-group-test .section--express-checkout-selection {
      padding-top: 3em !important;
      display: block
  }
}

.section--express-checkout-selection-group-control {
  display: table;
  width: 100%
}

.section--express-checkout-selection-group-control .section--contact-information {
  display: table-header-group
}

.section--express-checkout-selection-group-control .section--express-checkout-selection {
  padding-top: 3em !important
}

.notice+.step__sections {
  margin-top: 1.5em
}

@media (min-width: 750px) {
  .notice+.step__sections {
      margin-top: 2.5em
  }
}

.step__sections+form {
  padding-top: 2em
}

@media (min-width: 1000px) {
  .step__sections+form {
      padding-top: 0
  }
}

.section__delivery-option-group {
  padding-top: 3em
}

.section__delivery-option-group:first-child {
  padding-top: 0
}

.section {
  position: relative;
  padding-top: 2em
}

@media (min-width: 750px) {
  .section {
      padding-top: 3em
  }
}

.section:first-child {
  padding-top: 0
}

.section--half-spacing-top,
.section--half-spacing-bottom+.section {
  padding-top: 1em
}

@media (min-width: 750px) {

  .section--half-spacing-top,
  .section--half-spacing-bottom+.section {
      padding-top: 1.5em
  }
}

.section__header {
  position: relative;
  margin-bottom: 1em
}

@media (min-width: 750px) {
  .section__header {
      margin-bottom: 1.5em
  }
}

.section__title {
  color: #333333
}

.section__text {
  margin-top: 0.25em
}

.section__hanging-icon {
  margin-bottom: 1em
}

@media (min-width: 750px) and (max-width: 999px) {
  .section__hanging-icon {
      position: absolute;
      right: 100%;
      top: -0.4em;
      margin-right: 1.5em
  }
}

@media (min-width: 1300px) {
  .section__hanging-icon {
      position: absolute;
      right: 100%;
      top: -0.4em;
      margin-right: 1.5em
  }
}

.section__content {
  zoom: 1
}

.section__content:after,
.section__content:before {
  content: "";
  display: table
}

.section__content:after {
  clear: both
}

.section__content__text {
  margin-top: 0.75em
}

.section__content__policy {
  margin-top: 1em
}

.section__content__column {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 2em
}

.section__content__column:first-of-type {
  margin-top: 0
}

@media (min-width: 750px) {
  .section__content__column {
      margin-top: 0;
      float: left
  }
}

@media (min-width: 750px) {
  .section__content__column--half {
      padding: 0 0.75em;
      width: 50%
  }

  .section__content__column--half:first-child {
      padding-left: 0
  }

  .section__content__column--half:last-child {
      padding-right: 0
  }
}

.section__footer {
  zoom: 1;
  margin-top: 1em
}

.section__footer:after,
.section__footer:before {
  content: "";
  display: table
}

.section__footer:after {
  clear: both
}

@media (min-width: 750px) {
  .section__footer {
      margin-top: 1.5em
  }
}

@media (min-width: 750px) and (max-width: 999px) {
  .section--page-title {
      margin-top: 1em
  }
}

.section--subscription-agreement .field--error input {
  border-color: #e22120;
  -webkit-box-shadow: 0 0 0 1px #e22120;
  box-shadow: 0 0 0 1px #e22120
}

.has-modal body {
  overflow: hidden
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px)
}

.js .modal-backdrop {
  -webkit-transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, background-color 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, background-color 0.5s ease-in-out
}

.modal-backdrop.modal-backdrop--centered-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.modal-backdrop.modal-backdrop--is-closing.modal-backdrop--fade-out-white {
  background-color: rgba(255, 255, 255, 0.8)
}

.modal-backdrop--is-visible {
  visibility: visible;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  opacity: 1
}

.modal {
  background: #fff;
  -webkit-box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  color: #545454;
  min-height: auto;
  visibility: hidden
}

@media (min-width: 750px) {
  .modal {
      border-radius: 8px;
      margin: 4em auto 5%;
      max-width: 60em;
      min-height: initial;
      width: 90%
  }
}

.modal.modal--centered {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center
}

.modal-backdrop--is-visible .modal {
  -webkit-animation: modal-open 0.5s cubic-bezier(0.2, 0.9, 0.3, 1);
  animation: modal-open 0.5s cubic-bezier(0.2, 0.9, 0.3, 1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s
}

.no-cssanimations .modal-backdrop--is-visible .modal {
  visibility: visible
}

.modal-backdrop--is-closing .modal {
  -webkit-animation: modal-close 0.25s ease-in-out;
  animation: modal-close 0.25s ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards
}

.modal__header {
  padding: 1.5em
}

@media (min-width: 750px) {
  .modal__header {
      padding: 1.5em 2.5em
  }
}

.modal__header {
  border-bottom: 1px solid #e6e6e6;
  zoom: 1
}

.modal__header:after,
.modal__header:before {
  content: "";
  display: table
}

.modal__header:after {
  clear: both
}

.display-table .modal__header {
  display: table;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%
}

.modal__header__title {
  color: #333333
}

.display-table .modal__header__title {
  display: table-cell;
  width: 100%
}

.display-table .modal__header__title:only-child {
  display: block
}

.no-js .modal__header__title,
.no-display-table .modal__header__title {
  float: left
}

.modal__content {
  padding: 1.5em
}

@media (min-width: 750px) {
  .modal__content {
      padding: 2.5em
  }

  .modal__content p {
      font-size: 1.1428571429em
  }
}

.modal__iframe {
  width: 100%
}

.modal__loading-icon {
  display: block;
  margin: 1em auto
}

.modal__close {
  position: relative;
  -webkit-transition: color 0.3s cubic-bezier(0.3, 0, 0, 1);
  transition: color 0.3s cubic-bezier(0.3, 0, 0, 1);
  vertical-align: middle;
  padding-left: 0.75em;
  white-space: nowrap
}

.display-table .modal__close {
  display: table-cell
}

.no-js .modal__close,
.no-display-table .modal__close {
  float: right
}

.modal__close-button {
  color: #737373
}

.modal__close-button:hover {
  color: #545454
}

.fieldset {
  margin: -0.4285714286em;
  zoom: 1
}

.fieldset:after,
.fieldset:before {
  content: "";
  display: table
}

.fieldset:after {
  clear: both
}

.fieldset--inline {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.fieldset--inline .field {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1
}

@media (max-width: 749px) {
  .fieldset--inline {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column
  }
}

.fieldset--inline .btn,
.fieldset--inline .sp-modal-toggle {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin: 0.4285714286em;
  width: auto
}

@media (min-width: 750px) {

  .anyflexbox .fieldset--inline .btn,
  .anyflexbox .fieldset--inline .sp-modal-toggle {
      padding-top: 0;
      padding-bottom: 0
  }
}

.fieldset-description {
  margin-top: 0.9285714286em
}

.field {
  width: 100%;
  float: left;
  padding: 0.4285714286em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

@media (min-width: 750px) {
  .floating-labels .field--third {
      width: 33.3333333333%
  }

  .floating-labels .field--two-thirds {
      width: 66.6666666667%
  }

  .floating-labels .field--half {
      width: 50%
  }

  .floating-labels .field--three-eights {
      width: 37.5%
  }

  .floating-labels .field--quarter {
      width: 25%
  }
}

.field__message {
  line-height: 1.3em;
  margin: 0.5714285714em 0 0.2857142857em
}

.field__message--error {
  display: none;
  color: #e22120
}

.field--error .field__message--error {
  display: block
}

.field__message__icon {
  margin-right: 0.25em;
  vertical-align: -3px
}

.field__label {
  text-align: left;
  font-weight: 600;
  margin: 0.5em 0;
  display: none
}

.main .field__label {
  color: #333333
}

.floating-labels .main .field__label {
  color: #737373
}

.sidebar .field__label {
  color: white
}

.floating-labels .sidebar .field__label {
  color: #737373
}

.content-box .field__label {
  color: #333333
}

.floating-labels .content-box .field__label {
  color: #737373
}

.default-background .field__label {
  color: #333333
}

.floating-labels .default-background .field__label {
  color: #737373
}

.main .field--active .field__label {
  color: #545454
}

.sidebar .field--active .field__label {
  color: #545454
}

.content-box .field--active .field__label {
  color: #545454
}

.default-background .field--active .field__label {
  color: #545454
}

.no-js .field__label {
  display: block
}

.floating-labels .field__label {
  font-size: 0.8571428571em;
  font-weight: normal;
  position: absolute;
  top: 0;
  width: 100%;
  margin-top: 0.4285714286em;
  margin-left: 1px;
  padding: 0 0.9166666667em;
  z-index: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  pointer-events: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  opacity: 0
}

.floating-labels .field--show-floating-label .field__label {
  -webkit-transform: none;
  transform: none;
  opacity: 1
}

.floating-labels .field__input-wrapper--icon-left .field__label {
  padding-left: 2.8571428571rem
}

.floating-labels .field__input-wrapper--icon-right .field__label {
  padding-right: 2.8571428571rem
}

.animate-floating-labels .field__label {
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out
}

.field__label--visible {
  display: block
}

.field__input-wrapper {
  position: relative
}

.field__input {
  border: 1px transparent solid;
  background-clip: padding-box;
  border-radius: 5px;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 0.9285714286em 0.7857142857em;
  word-break: normal;
  line-height: inherit
}

.main .field__input {
  background-color: white;
  color: #333333;
  border-color: #d9d9d9
}

.main .field__input::-webkit-input-placeholder {
  color: #737373
}

.main .field__input:-moz-placeholder {
  color: #737373;
  opacity: 1
}

.main .field__input::-moz-placeholder {
  color: #737373;
  opacity: 1
}

.main .field__input:-ms-input-placeholder {
  color: #737373
}

.main .field__input:disabled {
  background-color: #fafafa;
  border-color: #cccccc
}

.sidebar .field__input {
  background-color: white;
  color: #333333;
  background-clip: border-box
}

.sidebar .field__input::-webkit-input-placeholder {
  color: #737373
}

.sidebar .field__input:-moz-placeholder {
  color: #737373;
  opacity: 1
}

.sidebar .field__input::-moz-placeholder {
  color: #737373;
  opacity: 1
}

.sidebar .field__input:-ms-input-placeholder {
  color: #737373
}

.sidebar .field__input:disabled {
  background-color: #1d3657;
  border-color: #4d607a
}

.content-box .field__input {
  background-color: white;
  color: #333333;
  border-color: #d9d9d9
}

.content-box .field__input::-webkit-input-placeholder {
  color: #737373
}

.content-box .field__input:-moz-placeholder {
  color: #737373;
  opacity: 1
}

.content-box .field__input::-moz-placeholder {
  color: #737373;
  opacity: 1
}

.content-box .field__input:-ms-input-placeholder {
  color: #737373
}

.content-box .field__input:disabled {
  background-color: #fafafa;
  border-color: #cccccc
}

.default-background .field__input {
  background-color: white;
  color: #333333;
  border-color: #d9d9d9
}

.default-background .field__input::-webkit-input-placeholder {
  color: #737373
}

.default-background .field__input:-moz-placeholder {
  color: #737373;
  opacity: 1
}

.default-background .field__input::-moz-placeholder {
  color: #737373;
  opacity: 1
}

.default-background .field__input:-ms-input-placeholder {
  color: #737373
}

.default-background .field__input:disabled {
  background-color: #fafafa;
  border-color: #cccccc
}

.animate-floating-labels .field__input {
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out
}

.floating-labels .field--show-floating-label .field__input {
  padding-top: 1.5em;
  padding-bottom: 0.3571428571em
}

.floating-labels .field--show-floating-label .field__input::-webkit-input-placeholder {
  color: transparent
}

.floating-labels .field--show-floating-label .field__input:-moz-placeholder {
  color: transparent;
  opacity: 1
}

.floating-labels .field--show-floating-label .field__input::-moz-placeholder {
  color: transparent;
  opacity: 1
}

.floating-labels .field--show-floating-label .field__input:-ms-input-placeholder {
  color: transparent
}

.field__input:focus,
.field__input-wrapper--flag-focus .field__input {
  outline: none;
  border-color: #2861a4;
  -webkit-box-shadow: 0 0 0 1px #2861a4;
  box-shadow: 0 0 0 1px #2861a4
}

@media (-ms-high-contrast: active) {

  .field__input:focus,
  .field__input-wrapper--flag-focus .field__input {
      background-color: highlight
  }
}

.field--error .field__input {
  border-color: #e22120;
  -webkit-box-shadow: 0 0 0 1px #e22120;
  box-shadow: 0 0 0 1px #e22120
}

.field__input-wrapper--icon-left .field__input {
  padding-left: 2.8571428571rem
}

.field__input-wrapper--icon-right .field__input {
  padding-right: 2.8571428571rem
}

.field__input-wrapper--flag-right .field__input {
  padding-right: 4.7142857143rem
}

.field__input[data-expanding] {
  height: 2.8571428571rem;
  resize: none;
  overflow: hidden;
  -webkit-transition: none;
  transition: none
}

.field__additional-info {
  line-height: 1.3em;
  margin: 0.75em 0 0.25em;
  color: #333333
}

.chrome .card-fields-container .field__label {
  will-change: transform
}

.field__input--iframe-container {
  border-color: #fafafa;
  min-height: 3.1428571429em;
  overflow: hidden;
  padding: 0;
  position: relative
}

.floating-labels .field--show-floating-label .field__input--iframe-container {
  padding-bottom: 0;
  padding-top: 0
}

.field__input--iframe-container::after,
.field__input--iframe-container::before {
  content: '';
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1
}

.field__input--iframe-container::before {
  background: #e6e6e6;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
  width: 100%
}

.field__input--iframe-container::after {
  -webkit-animation: load 1s infinite ease-in-out both;
  animation: load 1s infinite ease-in-out both;
  background: #fafafa;
  border-radius: 8px;
  height: 1em;
  margin-top: -0.5em;
  top: 50%;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out
}

.card-fields-container--loaded .field__input--iframe-container {
  height: auto
}

.card-fields-container--loaded .field__input--iframe-container::after,
.card-fields-container--loaded .field__input--iframe-container::before {
  opacity: 0
}

.card-fields-container--loaded .field__input--iframe-container::after {
  left: 100%
}

.card-fields-container--transitioned .field__input--iframe-container::after,
.card-fields-container--transitioned .field__input--iframe-container::before {
  content: none
}

.card-fields-container--loaded .field--active .field__input--iframe-container {
  outline: none;
  border-color: #2861a4;
  -webkit-box-shadow: 0 0 0 1px #2861a4;
  box-shadow: 0 0 0 1px #2861a4
}

.card-fields-container--loaded .field--error .field__input--iframe-container {
  border-color: #e22120;
  -webkit-box-shadow: 0 0 0 1px #e22120;
  box-shadow: 0 0 0 1px #e22120
}

@-webkit-keyframes load {
  0% {
      left: 1em;
      width: 0
  }

  50% {
      left: 1em;
      width: calc(100% - 2em)
  }

  100% {
      left: calc(100% - 1em);
      width: 0
  }
}

@keyframes load {
  0% {
      left: 1em;
      width: 0
  }

  50% {
      left: 1em;
      width: calc(100% - 2em)
  }

  100% {
      left: calc(100% - 1em);
      width: 0
  }
}

.card-fields-iframe {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  color: inherit;
  display: block;
  font: inherit;
  -webkit-font-smoothing: inherit;
  height: 3.1428571429em;
  line-height: normal;
  width: 100%
}

.card-fields-styling-options {
  display: none;
  position: relative;
  background-color: white;
  color: #333333;
  z-index: 22
}

.field__input--zip {
  text-transform: uppercase
}

.field__input--zip::-webkit-input-placeholder {
  text-transform: none
}

.field__input--zip:-moz-placeholder {
  text-transform: none;
  opacity: 1
}

.field__input--zip::-moz-placeholder {
  text-transform: none;
  opacity: 1
}

.field__input--zip:-ms-input-placeholder {
  text-transform: none
}

.field__prefix {
  display: none;
  pointer-events: none;
  position: absolute;
  border: 1px transparent solid;
  padding-top: 1.5em;
  padding-left: 0.7857142857em
}

.field--with-prefix .field__input {
  -webkit-transition: initial;
  transition: initial
}

.field--show-currency .field__input {
  padding-left: 2em
}

.field--show-currency .field__prefix {
  display: block
}

.field__input--select {
  padding-right: 2.0714285714em;
  white-space: nowrap
}

.field__input--select:-moz-focusring {
  color: transparent;
  -moz-transition: color 0ms;
  transition: color 0ms
}

.main .field__input--select:-moz-focusring {
  text-shadow: 0 0 0 #333333
}

.sidebar .field__input--select:-moz-focusring {
  text-shadow: 0 0 0 #333333
}

.content-box .field__input--select:-moz-focusring {
  text-shadow: 0 0 0 #333333
}

.default-background .field__input--select:-moz-focusring {
  text-shadow: 0 0 0 #333333
}

.field__caret {
  display: block;
  width: 2.1428571429em;
  height: 43%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%)
}

.main .field__caret {
  border-left: 1px rgba(179, 179, 179, 0.5) solid
}

.sidebar .field__caret {
  border-left: 1px rgba(179, 179, 179, 0.5) solid
}

.content-box .field__caret {
  border-left: 1px rgba(179, 179, 179, 0.5) solid
}

.default-background .field__caret {
  border-left: 1px rgba(179, 179, 179, 0.5) solid
}

.field__caret-svg {
  position: absolute;
  margin-left: -2px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.field__icon {
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 2.8571428571rem
}

.field__icon:focus {
  outline: none
}

.field__input-wrapper--icon-right .field__icon {
  right: 0
}

.field__input-wrapper--icon-left .field__icon {
  left: 0
}

.field__icon-svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.anyflexbox .field__input-btn-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.anyflexbox .field__input-btn-wrapper .field__input-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1
}

.anyflexbox .field__input-btn {
  width: auto;
  margin-left: 0.8571428571em;
  white-space: nowrap;
  padding-top: 0;
  padding-bottom: 0
}

.no-js .field__input-btn,
.no-anyflexbox .field__input-btn {
  margin-top: 0.8571428571em;
  padding: 1em 1.5em
}

.combo-box {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px rgba(2, 2, 2, 0.15) solid;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.23);
  box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.23);
  margin-top: 0.4285714286em;
  position: absolute;
  top: 100%;
  left: -1px;
  width: calc(100% + 2px);
  z-index: 3
}

.combo-box--hidden {
  display: none
}

.combo-box--with-footer .combo-box__options {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0
}

.combo-box--with-footer .combo-box__option:last-child {
  border-radius: 0
}

.combo-box__options {
  display: block;
  overflow-y: auto;
  max-height: 230px
}

.combo-box__option {
  color: #545454;
  cursor: pointer
}

.combo-box__option:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px
}

.combo-box__option--selected {
  background-color: rgba(0, 0, 0, 0.08);
  border: 1px transparent solid
}

.combo-box__option--selected .combo-box__content {
  margin: -1px
}

.combo-box__content {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  padding: 0.9285714286em 0.7857142857em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.combo-box__content * {
  pointer-events: none
}

.combo-box__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.7857142857em
}

.combo-box__header__title {
  font-size: 0.8571428571em;
  font-weight: 400;
  line-height: 1.3em;
  margin: 0;
  text-transform: uppercase
}

.main .combo-box__header__title {
  color: #737373
}

.sidebar .combo-box__header__title {
  color: #737373
}

.content-box .combo-box__header__title {
  color: #737373
}

.default-background .combo-box__header__title {
  color: #737373
}

.combo-box__header__button {
  color: #737373;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  padding: 0.7857142857em;
  margin: -0.7857142857em
}

.combo-box__header__button:hover {
  color: #545454
}

.combo-box__footer {
  background-color: rgba(0, 0, 0, 0.02);
  padding: 0.7428571429em 0.7857142857em;
  position: relative;
  z-index: 4;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px
}

.content-box {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #DDD !important;
  border-radius: 0px !important;
  color: #545454;
  width: 100%;
}

.main .content-box {
  border-color: #d9d9d9 !important
}

.content-box .content-box {
  border-color: #d9d9d9
}

.main {
  &.order-confirmation-main {
      .content-box {
          margin-top: 1em;

          &:first-of-type {
              margin-top: 0;
          }
      }

      .step {
          &__footer {
              a {
                  &.btn {
                      &:hover {
                          border: 1px transparent solid;
                          background-color: #102b4e !important;
                      }
                  }
              }
          }
      }

      [class*=btn] {
          &:hover {
              &:not(:disabled) {
                  /*background-color: #102b4e;*/
                  color: #fff;
              }
          }
      }
  }
}

.content-box,
.content-box-spacing {
  // margin-top: 1em
}

.content-box:first-of-type,
.content-box-spacing:first-of-type {
  margin-top: 0
}

.content-box__emphasis {
  font-weight: 600;
  color: #333333
}

.content-box__small-text {
  font-size: 0.8571428571em;
  color: #737373
}

.content-box__sub-content {
  padding-top: 0.2857142857em;
  font-size: 1em;
  color: #737373
}

.content-box__row {
  padding: 1.1428571429em;
  position: relative;
  zoom: 1
}

.content-box__row~.content-box__row {
  border-top: 1px solid #d9d9d9
}

.content-box__row:after,
.content-box__row:before {
  content: "";
  display: table
}

.content-box__row:after {
  clear: both
}

.display-table .content-box__row {
  display: table;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%
}

.content-box__row:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px
}

.content-box__row:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px
}

.content-box__row--main-content {
  display: table-row
}

.content-box__row--main-content .apple-pay-logo {
  height: 19px;
  width: 47px;
  background-color: white;
  display: block;
  background-image: -webkit-named-image(apple-pay-logo-black)
}

.content-box__row--sub-content {
  display: table-row
}

.content-box__row--tight-spacing-vertical {
  padding-top: 0.8571428571em;
  padding-bottom: 0.8571428571em
}

.content-box__row--secondary {
  background-color: #fafafa
}

.content-box__row--no-border {
  padding-bottom: 0
}

.content-box__row+.content-box__row--no-border {
  border-top: none;
  padding-top: 0
}

.content-box__row--no-border+.content-box__row {
  border-top: none
}

.content-box__row--no-padding {
  padding: 0
}

.content-box__row--with-footer {
  padding-bottom: 0.8571428571em
}

.content-box__panel {
  padding: 1.1428571429em;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #fafafa
}

.content-box__row--details {
  margin-left: 2em
}

.content-box__row--details+.content-box__row--details {
  margin-top: 0.8571428571em
}

.content-box__row--details p {
  line-height: 1.75em
}

.display-table .content-box__row__stretch {
  display: table-cell;
  width: 100%
}

.display-table .content-box__row__stretch:only-child {
  display: block
}

.no-js .content-box__row__stretch,
.no-display-table .content-box__row__stretch {
  float: left
}

.content-box__row__right {
  padding-left: 0.75em;
  white-space: nowrap
}

.display-table .content-box__row__right {
  display: table-cell
}

.no-js .content-box__row__right,
.no-display-table .content-box__row__right {
  float: right
}

.content-box__hr {
  border: 0;
  border-bottom: 1px solid #e6e6e6;
  margin: 1.1428571429em 0
}

.content-box__hr--tight {
  margin: 0.8571428571em 0
}

.content-box--applied-reduction-code-list {
  margin-top: 0.8571428571em;
  background-color: #fafafa
}

.content-box__header {
  zoom: 1
}

.content-box__header:after,
.content-box__header:before {
  content: "";
  display: table
}

.content-box__header:after {
  clear: both
}

.display-table .content-box__header {
  display: table;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%
}

.content-box__header~.content-box__header {
  margin-top: 1.1428571429em
}

.display-table .content-box__header__title {
  display: table-cell;
  width: 100%
}

.display-table .content-box__header__title:only-child {
  display: block
}

.no-js .content-box__header__title,
.no-display-table .content-box__header__title {
  float: left
}

.content-box__footer {
  border-top: 1px solid #e6e6e6;
  padding-top: 0.8571428571em;
  margin-top: 0.8571428571em
}

.subscription-shipping-rate-options {
  text-align: right
}

.content-box__row.duties-selector__no-border {
  border-top: none
}

@media (max-width: 999px) {

  .order-summary--transition,
  .order-summary--is-collapsed {
      overflow: hidden
  }
}

@media (max-width: 999px) {
  .js .order-summary--is-collapsed {
      height: 0;
      visibility: hidden
  }
}

.order-summary--transition {
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0, 1)
}

.order-summary__section {
  /*padding-top: 1.5em;
  padding-bottom: 1.5em*/
}

.order-summary__section~.order-summary__section {
  border-top: 1px solid rgba(100, 117, 140, 0.34)
}

@media (min-width: 1000px) {
  .order-summary__section {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto
  }

  .order-summary__section:first-child {
      padding-top: 0
  }
}

@media (min-width: 1000px) {
  .order-summary__sections {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      height: calc(100vh - 8em);
      width: 100%
  }

  .no-js .order-summary__sections,
  .no-anyflexbox .order-summary__sections {
      height: auto
  }

  .order-summary__section--product-list {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      // -webkit-flex: 0 1 auto;
      // -ms-flex: 0 1 auto;
      // flex: 0 1 auto;
      min-height: 7em;
      overflow: hidden;
      padding: 0;
      position: relative
  }

  .order-summary__section--product-list+.order-summary__section {
      border: 0
  }

  .order-summary__section--product-list::before {
      content: '';
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      -webkit-transition: background-color 0.3s cubic-bezier(0.3, 0, 0, 1);
      transition: background-color 0.3s cubic-bezier(0.3, 0, 0, 1);
      width: 100%;
      z-index: 4
  }

  .order-summary__section--product-list::before {
      background-color: transparent;
      top: 0
  }

  .order-summary__section--product-list .product-table {
      margin-top: 0.75em;
      margin-bottom: 1.5em;
      position: relative;
      z-index: 1
  }

  .order-summary__section--has-scroll::before {
      background-color: rgba(100, 117, 140, 0.34)
  }

  .cartReview {
      .order-summary__section__content {
          overflow: hidden;

          .product-table {
              display: block;

              tbody {
                  display: flex;
                  flex-direction: column;

                  .confirmation_product {
                     /* padding-bottom: 1.2em;*/

                      .product__description__name {
                          width: 85%;
                      }
                  }
              }
          }
      }
  }

  .order-summary__section__content {
      min-width: 100%;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch
  }

  .no-js .order-summary__section__content,
  .no-touchevents .order-summary__section__content {
      overflow: hidden
  }

  .no-js .order-summary__section__content:hover,
  .no-js .order-summary__section__content:focus,
  .no-touchevents .order-summary__section__content:hover,
  .no-touchevents .order-summary__section__content:focus {
      overflow-y: auto
  }
}

.order-summary__scroll-indicator {
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 2em;
  bottom: 1.5em;
  color: #112b4e;
  font-size: 0.8571428571em;
  left: 50%;
  opacity: 0;
  padding: 0.6em 1.2em;
  position: absolute;
  -webkit-transform: translateX(-50%) translateY(100%) rotateZ(360deg);
  transform: translateX(-50%) translateY(100%) rotateZ(360deg);
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
  white-space: nowrap;
  z-index: 2
}

.order-summary__section--is-scrollable .order-summary__scroll-indicator {
  opacity: 1;
  -webkit-transform: translateX(-50%) translateY(0) rotateZ(360deg);
  transform: translateX(-50%) translateY(0) rotateZ(360deg)
}

.order-summary__section--has-scroll .order-summary__scroll-indicator {
  -webkit-transform: translateX(-50%) translateY(-100%) rotateZ(360deg);
  transform: translateX(-50%) translateY(-100%) rotateZ(360deg)
}

.order-summary__scroll-indicator .icon-svg {
  margin-left: 0.2142857143em;
  margin-top: -0.2142857143em
}

.order-summary__emphasis {
  font-weight: 600
}

.sidebar .order-summary__emphasis {
  color: white
}

.content-box .order-summary__emphasis {
  color: #333333
}

.order-summary__small-text {
  font-size: 0.8571428571em
}

.sidebar .order-summary__small-text {
  color: #a0aab8
}

.content-box .order-summary__small-text {
  color: #737373
}

.total-line th,
.total-line td {
  padding-top: 0.75em
}

.total-line:first-child th,
.total-line:first-child td {
  padding-top: 0
}

.content-box .total-line__name {
  color: #737373
}

.total-line__name .reduction-code {
  margin-left: 0.5714285714em
}

.total-line__name--top-aligned {
  vertical-align: top
}

.total-line__price {
  padding-left: 1.5em;
  text-align: right;
  white-space: nowrap
}

.total-line--shipping-policy-link {
  padding-top: 0.25em
}

.total-line__duties-container {
  position: relative;
  display: inline
}

.total-line-table__tbody+.total-line-table__tbody .total-line:first-child th,
.total-line-table__tbody+.total-line-table__tbody .total-line:first-child td,
.total-line-table__tbody+.total-line-table__footer .total-line:first-child th,
.total-line-table__tbody+.total-line-table__footer .total-line:first-child td {
  padding-top: 3em;
  position: relative;
}

.total-line-table__tbody+.total-line-table__tbody .total-line:first-child th::before,
.total-line-table__tbody+.total-line-table__tbody .total-line:first-child td::before,
.total-line-table__tbody+.total-line-table__footer .total-line:first-child th::before,
.total-line-table__tbody+.total-line-table__footer .total-line:first-child td::before {
  content: '';
  position: absolute;
  top: 1.5em;
  left: 0;
  width: 100%;
  height: 1px
}

.sidebar .total-line-table__tbody+.total-line-table__tbody .total-line:first-child th::before,
.sidebar .total-line-table__tbody+.total-line-table__tbody .total-line:first-child td::before,
.sidebar .total-line-table__tbody+.total-line-table__footer .total-line:first-child th::before,
.sidebar .total-line-table__tbody+.total-line-table__footer .total-line:first-child td::before {
  background-color: rgba(100, 117, 140, 0.34)
}

.content-box .total-line-table__tbody+.total-line-table__tbody .total-line:first-child th::before,
.content-box .total-line-table__tbody+.total-line-table__tbody .total-line:first-child td::before,
.content-box .total-line-table__tbody+.total-line-table__footer .total-line:first-child th::before,
.content-box .total-line-table__tbody+.total-line-table__footer .total-line:first-child td::before {
  background-color: rgba(179, 179, 179, 0.34);
}

.payment-due-label__total {
  font-size: 1.1428571429em
}

.sidebar .payment-due-label__total {
  color: white
}

.content-box .payment-due-label__total {
  color: #333333
}

.payment-due-label__taxes {
  display: block
}

.sidebar .payment-due-label__taxes {
  color: #a0aab8
}

.content-box .payment-due-label__taxes {
  color: #737373
}

.payment-due__price {
  font-size: 1.7142857143em;
  font-weight: 600;
  letter-spacing: -0.04em;
  line-height: 1em
}

.sidebar .payment-due__price {
  color: white
}

.content-box .payment-due__price {
  color: #333333
}

.payment-due__currency {
  font-size: 0.8571428571em;
  vertical-align: 0.2em;
  margin-right: 0.5em
}

.sidebar .payment-due__currency {
  color: #a0aab8
}

.content-box .payment-due__currency {
  color: #737373
}

.total-line--shipping-details,
.total-line--recurring-total,
.total-line--recurring-total-details {
  color: #a0aab8
}

.shipping_details__controls {
  outline: none
}

.recurring_total__controls {
  cursor: pointer
}

.shipping_details__controls.expand svg,
.recurring_total__controls.expand .recurring_total__chevron-icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.shipping_details__controls.collapse svg,
.recurring_total__controls.collapse .recurring_total__chevron-icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg)
}

.recurring-total-label {
  white-space: nowrap;
  vertical-align: top
}

.recurring-total-label-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.recurring-total-product-name,
.recurring-total-detail {
  white-space: normal;
  vertical-align: top
}

.recurring-total-tooltip-wrapper {
  display: block;
  width: 1.4285714286rem
}

.reduction-code__icon {
  margin-right: 0.1428571429em;
  vertical-align: top
}

.reduction-code__text {
  font-size: 1em
}

.sidebar .reduction-code__text {
  color: white
}

.content-box .reduction-code__text {
  color: #333333
}

.full-page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: white;
  color: #545454
}

.full-page-overlay__wrap {
  margin: auto;
  width: 100%;
  padding: 10% 0
}

.full-page-overlay__title {
  color: #333333;
  margin-bottom: 0.5em
}

.full-page-overlay__content {
  margin: 0 auto;
  max-width: 36em;
  padding-left: 1em;
  padding-right: 1em;
  zoom: 1
}

.full-page-overlay__content:after,
.full-page-overlay__content:before {
  content: "";
  display: table
}

.full-page-overlay__content:after {
  clear: both
}

.full-page-overlay__content form {
  margin: 1.5em 0
}

.full-page-overlay__content:focus {
  outline: 0
}

.full-page-overlay__icon {
  margin-bottom: 1.5em
}

.full-page-overlay__illustration {
  margin-bottom: 1.5em
}

.full-page-overlay__action {
  margin-top: 1.5em
}

.tooltip {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  bottom: 80%;
  right: -0.3333333333em;
  width: 180px;
  margin: 0 0 5px 0;
  padding: 0.75em 1em;
  border-radius: 4px;
  white-space: normal;
  font-size: 0.8571428571em;
  font-style: normal;
  text-align: center;
  color: #fff;
  display: block;
  z-index: 200;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
  -webkit-transform: rotateX(20deg) scale(0.8);
  transform: rotateX(20deg) scale(0.8);
  -webkit-transform-origin: 90% 120%;
  transform-origin: 90% 120%;
  -webkit-backface-visibility: hidden;
  background-color: rgba(51, 51, 51, 0.9);
  visibility: hidden;
  opacity: 0
}

.tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  right: 18px;
  width: 0;
  border: 6px solid transparent;
  border-top-color: rgba(51, 51, 51, 0.9)
}

@media (min-width: 750px) {
  .tooltip {
      -webkit-transform-origin: center 120%;
      transform-origin: center 120%;
      right: 50%;
      margin-right: -90px
  }

  .tooltip:after {
      right: 50%;
      margin-right: -6px
  }
}

.has-tooltip {
  cursor: pointer
}

.tooltip-container {
  position: relative;
  height: 100%;
  width: 100%
}

.tooltip-container .tooltip-control {
  position: absolute;
  height: 100%;
  width: 100%
}

.tooltip-container.show-tooltip .tooltip,
.tooltip-container:hover .tooltip,
.tooltip-container:focus .tooltip,
.has-tooltip.show-tooltip .tooltip,
.has-tooltip:hover .tooltip,
.has-tooltip:focus .tooltip {
  -webkit-transform: rotateX(0deg) scale(1);
  transform: rotateX(0deg) scale(1);
  pointer-events: auto;
  visibility: visible;
  opacity: 1
}

.tooltip--right-align {
  right: -130px;
  bottom: 108%
}

.tooltip--right-align:after {
  right: 130px
}

@media (min-width: 750px) {
  .tooltip--right-align {
      -webkit-transform-origin: center 120%;
      transform-origin: center 120%;
      right: 50%;
      margin-right: -90px
  }

  .tooltip--right-align:after {
      right: 50%;
      margin-right: -6px
  }
}

.tooltip--vertical-align {
  margin-top: -3px
}

.breadcrumb {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.breadcrumb__item {
  display: inline;
  font-size: 0.8571428571em;
  color: #737373
}

.anyflexbox .breadcrumb__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.breadcrumb__item--current {
  font-weight: 600;
  color: #333333
}

.breadcrumb__chevron-icon {
  margin: 0 0.6428571429em
}

.breadcrumb--center {
  text-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center
}

.main__footer {
  padding: 1em 0;
  border-top: 1px solid #e6e6e6
}

.copyright-text {
  font-size: 0.8571428571em;
  color: #737373
}

.copyright-text--left {
  text-align: left
}

.copyright-text--center {
  text-align: center
}

.copyright-text--right {
  text-align: right
}

.policy-list {
  zoom: 1;
  text-align: center
}

.policy-list:after,
.policy-list:before {
  content: "";
  display: table
}

.policy-list:after {
  clear: both
}

.policy-list__item {
  float: left;
  font-size: 0.8571428571em;
  margin-right: 1.5em;
  margin-bottom: 0.5em
}

.policy-list__item--left {
  float: left;
  margin-right: 1.5em
}

.policy-list__item--center {
  display: inline;
  float: none;
  margin-left: 0.75em;
  margin-right: 0.75em
}

.policy-list__item--right {
  float: right;
  margin-left: 1.5em;
  margin-right: 0
}

.input-checkbox,
.input-radio {
  width: 18px;
  height: 18px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
  cursor: pointer;
  vertical-align: -4px;
  border: 1px solid
}

.main .input-checkbox,
.main .input-radio {
  border-color: #d9d9d9;
  background-color: white
}

.main .input-checkbox:hover,
.main .input-radio:hover {
  border-color: #cccccc
}

.main .input-checkbox:disabled,
.main .input-radio:disabled {
  background-color: #fafafa;
  border-color: #cccccc
}

.sidebar .input-checkbox,
.sidebar .input-radio {
  border-color: #415571;
  background-color: white
}

.sidebar .input-checkbox:hover,
.sidebar .input-radio:hover {
  border-color: #4d607a
}

.sidebar .input-checkbox:disabled,
.sidebar .input-radio:disabled {
  background-color: #1d3657;
  border-color: #4d607a
}

.content-box .input-checkbox,
.content-box .input-radio {
  border-color: #d9d9d9;
  background-color: white
}

.content-box .input-checkbox:hover,
.content-box .input-radio:hover {
  border-color: #cccccc
}

.content-box .input-checkbox:disabled,
.content-box .input-radio:disabled {
  background-color: #fafafa;
  border-color: #cccccc
}

.default-background .input-checkbox,
.default-background .input-radio {
  border-color: #d9d9d9;
  background-color: white
}

.default-background .input-checkbox:hover,
.default-background .input-radio:hover {
  border-color: #cccccc
}

.default-background .input-checkbox:disabled,
.default-background .input-radio:disabled {
  background-color: #fafafa;
  border-color: #cccccc
}

.input-checkbox:focus,
.input-radio:focus {
  border-width: 2px;
  border-color: #324047
}

.input-checkbox:checked,
.input-radio:checked {
  border-color: #324047;
}

.input-checkbox:checked:hover,
.input-radio:checked:hover {
  border-color: #324047;
}

.input-radio {
  border-radius: 50%
}

.input-radio:checked {
  border-width: 7px
}

.input-checkbox {
  border-radius: 4px
}

.input-checkbox:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  width: 10px;
  height: 8px;
  margin-left: -5px;
  margin-top: -4px;
  background-image: url('/themes/icons/tick.png');
  background-image: url('/themes/icons/tick.svg'), none;
}

.input-checkbox:checked {
  border-width: 9px
}

.input-checkbox:checked:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  opacity: 1
}

.radio-wrapper,
.checkbox-wrapper {
  zoom: 1;
  margin-bottom: 1em
}

.radio-wrapper:after,
.radio-wrapper:before,
.checkbox-wrapper:after,
.checkbox-wrapper:before {
  content: "";
  display: table
}

.radio-wrapper:after,
.checkbox-wrapper:after {
  clear: both
}

.display-table .radio-wrapper,
.display-table .checkbox-wrapper {
  display: table;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%
}

.radio-wrapper:last-child,
.checkbox-wrapper:last-child {
  margin-bottom: 0
}

.radio-wrapper.content-box__row,
.checkbox-wrapper.content-box__row {
  margin-bottom: 0
}

.radio__input,
.checkbox__input {
  padding-right: 0.75em;
  white-space: nowrap
}

.display-table .radio__input,
.display-table .checkbox__input {
  display: table-cell
}

.no-js .radio__input,
.no-display-table .radio__input,
.no-js .checkbox__input,
.no-display-table .checkbox__input {
  float: left
}

.radio__label,
.checkbox__label {
  cursor: pointer;
  vertical-align: middle
}

.display-table .radio__label,
.display-table .checkbox__label {
  display: table-cell;
  width: 100%
}

.display-table .radio__label:only-child,
.display-table .checkbox__label:only-child {
  display: block
}

.no-js .radio__label,
.no-display-table .radio__label,
.no-js .checkbox__label,
.no-display-table .checkbox__label {
  float: left
}

.radio__label--active {
  color: #2861a4
}

.radio__label--inactive {
  cursor: default
}

.radio__label__primary {
  cursor: inherit;
  font-family: inherit;
  vertical-align: top
}

.display-table .radio__label__primary {
  display: table-cell;
  width: 100%
}

.display-table .radio__label__primary:only-child {
  display: block
}

.no-js .radio__label__primary,
.no-display-table .radio__label__primary {
  float: left
}

.radio__label__accessory {
  text-align: right;
  padding-left: 0.75em;
  white-space: nowrap
}

.display-table .radio__label__accessory {
  display: table-cell
}

.no-js .radio__label__accessory,
.no-display-table .radio__label__accessory {
  float: right
}

.payment-method-wrapper .radio__label__accessory {
  vertical-align: middle
}

@media (max-width: 749px) {
  .payment-method-wrapper .radio-wrapper {
      display: block
  }

  .payment-method-wrapper .radio__label,
  .payment-method-wrapper .radio__label__accessory {
      display: block;
      padding-left: 0;
      text-align: left
  }

  .payment-method-wrapper .radio__label__primary::after {
      content: '';
      display: block;
      height: 0.65em
  }
}

.payment-icon {
  display: inline-block;
  width: 38px;
  height: 24px;
  -webkit-transition: opacity 0.5s cubic-bezier(0.3, 0, 0, 1);
  transition: opacity 0.5s cubic-bezier(0.3, 0, 0, 1);
  -webkit-backface-visibility: hidden
}

.blank-slate .payment-icon {
  vertical-align: middle
}

.payment-icon {
  border-radius: 0.2142857143em;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 2.7142857143em;
  height: 1.7142857143em
}

.radio__label__accessory .payment-icon {
  cursor: default;
  margin: -0.1428571429em 0 -0.4285714286em
}

.payment-icon--known {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.05)
}

.payment-icon--unknown {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5
}

.offsite-payment-gateway-logo {
  height: 24px;
  display: block;
  margin-top: -2px
}

.payment-method-list__item+.payment-method-list__item {
  margin-top: 0.5em
}

.payment-method-list__item-icon {
  vertical-align: middle;
  margin: -0.1em 0.25em 0 0
}

.payment-method-list__item-link {
  white-space: nowrap
}

.payment-icon-list__more {
  vertical-align: middle;
  margin-top: -2px;
  margin-left: 0.25em;
  display: inline-block;
  cursor: default
}

.wallet-card-summary-logo {
  display: inline-block;
  height: 1.28em;
  vertical-align: middle;
  width: auto
}

.amazon-payments__widget {
  width: 100%;
  height: 400px
}

.amazon-payments__link {
  display: block;
  margin-top: 1.5em
}

.venmo__logo {
  width: 6.7857142857em;
  height: auto;
  margin: -2px;
  display: block
}

.currency-selector__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.currency-selector__label {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-right: 1em
}

.currency-selector__amount {
  font-weight: 600
}

.currency-selector__rate {
  font-size: 0.8571428571em;
  padding-top: 0.5em;
  color: #737373
}

.currency-selector__change-currency-row {
  padding-top: 1em;
  font-size: 0.8571428571em
}

.currency-selector__change-currency-link {
  cursor: pointer
}

.blank-slate {
  padding: 1.1428571429em;
  text-align: center
}

@media (min-width: 750px) {
  .blank-slate {
      padding-left: 4.5em;
      padding-right: 4.5em
  }
}

.blank-slate__title {
  display: block;
  margin-bottom: 0.5714285714em
}

.blank-slate__icon {
  margin-bottom: 1.1428571429em
}

.blank-slate__btn {
  margin-top: 1.1428571429em
}

.notice {
  position: relative;
  display: table;
  opacity: 1;
  margin-bottom: 1.4285714286em;
  padding: 1em;
  border-radius: 4px;
  border: 1px solid #d3e7f5;
  background-color: #eff8ff;
  color: #545454;
  -webkit-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out
}

.step__sections+.notice {
  margin-top: 0.7142857143em
}

.notice+.step__footer {
  margin-top: 0
}

.notice .product-table {
  margin-top: 1.5em
}

.notice .product-table td {
  border-color: #d3e7f5
}

.notice .product-table tr:last-child td {
  padding-bottom: 0
}

.notice__content {
  display: table-cell;
  width: 100%;
  padding-right: 1.1428571429em
}

.notice__complementary {
  margin-top: 0.75em
}

.notice__action {
  margin-top: 1.5em;
  border-color: #d3e7f5;
  color: #545454
}

.notice__action:hover {
  background-color: rgba(211, 231, 245, 0.06);
  color: #545454
}

.notice__action:focus,
.notice__action:active {
  border-color: #d3e7f5;
  -webkit-box-shadow: 0 0 0 1px #d3e7f5 inset;
  box-shadow: 0 0 0 1px #d3e7f5 inset
}

.notice__icon {
  color: #1878b9;
  margin-top: -0.1428571429em;
  margin-right: 0.7142857143em
}

.notice__controls {
  position: relative;
  padding: 1.1428571429em;
  margin: -1.1428571429em;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out
}

.notice__controls:hover {
  color: #333333
}

.notice--success {
  border-color: #d1ecd4;
  background-color: #e9f8ec
}

.notice--success .notice__icon {
  color: #0ca227
}

.notice--success .notice__separator {
  border-color: #d1ecd4
}

.notice--success .notice__action {
  border-color: #d1ecd4
}

.notice--success .notice__action:hover {
  background-color: rgba(209, 236, 212, 0.06)
}

.notice--success .notice__action:focus,
.notice--success .notice__action:active {
  border-color: #d1ecd4;
  -webkit-box-shadow: 0 0 0 1px #d1ecd4 inset;
  box-shadow: 0 0 0 1px #d1ecd4 inset
}

.product-table td {
  border-color: #d1ecd4
}

.notice--warning {
  border-color: #f0e4bc;
  background-color: #fff8de
}

.notice--warning .notice__icon {
  color: #b88600
}

.notice--warning .notice__separator {
  border-color: #f0e4bc
}

.notice--warning .notice__action {
  border-color: #f0e4bc
}

.notice--warning .notice__action:hover {
  background-color: rgba(240, 228, 188, 0.06)
}

.notice--warning .notice__action:focus,
.notice--warning .notice__action:active {
  border-color: #f0e4bc;
  -webkit-box-shadow: 0 0 0 1px #f0e4bc inset;
  box-shadow: 0 0 0 1px #f0e4bc inset
}

.product-table td {
  border-color: #f0e4bc
}

.notice--error {
  border-color: #fad9d9;
  background-color: #ffebeb
}

.notice--error .notice__icon {
  color: #e22120
}

.notice--error .notice__separator {
  border-color: #fad9d9
}

.notice--error .notice__action {
  border-color: #fad9d9
}

.notice--error .notice__action:hover {
  background-color: rgba(250, 217, 217, 0.06)
}

.notice--error .notice__action:focus,
.notice--error .notice__action:active {
  border-color: #fad9d9;
  -webkit-box-shadow: 0 0 0 1px #fad9d9 inset;
  box-shadow: 0 0 0 1px #fad9d9 inset
}

.product-table td {
  border-color: #fad9d9
}

.notice--hide {
  opacity: 0
}

.notice--collapsed .notice__complementary {
  display: none
}

.notice--collapsed .icon-svg--rotate-180 {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg)
}

.product td {
  padding-top: 1em
}

.product:first-child td {
  padding-top: 0
}

.product-thumbnail {
  width: 4.6em;
  height: 4.6em;
  border-radius: 8px;
  background: #fff;
  position: relative
}

.product-thumbnail::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 8px;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  z-index: 2
}

.product-thumbnail--small {
  width: 3em;
  height: 3em;
  border-radius: 6px
}

.product-thumbnail--small::after {
  border-radius: 6px
}

.product-thumbnail--small .product-thumbnail__wrapper {
  border-radius: 6px
}



.product-thumbnail__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: auto !important;
  border-radius: 0;
}

.product-thumbnail__quantity {
  font-size: 0.8571428571em;
  font-weight: 600;
  line-height: 1.75em;
  white-space: nowrap;
  text-align: center;
  border-radius: 1.75em;
  background-color: rgba(114, 114, 114, 0.9);
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 1.75em;
  height: 1.75em;
  padding: 0 0.5833333333em;
  position: absolute;
  right: -0.75em;
  top: -0.75em;
  z-index: 3
}

.product__price {
  white-space: nowrap;
  vertical-align: top;
}

.product__description__name,
.product__description__variant,
.product__description__property,
.product__description__full_price {
  display: block
}

.product__description {
  text-align: left;
  width: 100%;
  vertical-align: top;
}

.product__description .reduction-code {
  margin-top: 0.2857142857em
}

.product__description .reduction-code__text {
  color: #a0aab8
}

.product__description__name {
  width: 1em;
  min-width: 100%
}

.product__status {
  position: relative;
  white-space: nowrap
}

.product__status--sold-out {
  color: #e22120
}

.product__status__icon {
  vertical-align: -0.15em
}

.product__clear-btn {
  position: absolute;
  right: -2.5em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

@media (max-width: 749px) {
  .product__clear-btn {
      display: none
  }
}

.logged-in-customer-information {
  zoom: 1
}

.logged-in-customer-information:after,
.logged-in-customer-information:before {
  content: "";
  display: table
}

.logged-in-customer-information:after {
  clear: both
}

.display-table .logged-in-customer-information {
  display: table;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%
}

.logged-in-customer-information__avatar-wrapper {
  padding-right: 1em;
  white-space: nowrap;
  vertical-align: middle
}

.display-table .logged-in-customer-information__avatar-wrapper {
  display: table-cell
}

.no-js .logged-in-customer-information__avatar-wrapper,
.no-display-table .logged-in-customer-information__avatar-wrapper {
  float: left
}

.logged-in-customer-information__avatar {
  border-radius: 8px;
  background-size: cover;
  position: relative;
  max-width: none;
  width: 50px;
  height: 50px;
  overflow: hidden
}

.logged-in-customer-information__avatar:before {
  background-repeat: no-repeat;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.logged-in-customer-information__paragraph {
  padding-top: 0.25em;
  vertical-align: middle
}

.display-table .logged-in-customer-information__paragraph {
  display: table-cell;
  width: 100%
}

.display-table .logged-in-customer-information__paragraph:only-child {
  display: block
}

.no-js .logged-in-customer-information__paragraph,
.no-display-table .logged-in-customer-information__paragraph {
  float: left
}

.logged-in-customer-newsletter {
  margin-top: 1.5em
}

@media (min-width: 1000px) {
  .alt-payment-list-container {
      padding-top: 1.5em
  }
}

@media (min-width: 1000px) {
  .alt-payment-list--center {
      text-align: center
  }
}

.alt-payment-list {
  font-size: 0
}

.alt-payment-list__item {
  position: relative;
  display: block;
  margin: 0 0 0.5em;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  vertical-align: middle
}

@media (min-width: 750px) {
  .alt-payment-list__item {
      display: inline-block;
      margin: 0.5em 0 0.5em 0.5em
  }

  .alt-payment-list__item:first-child {
      margin-left: 0
  }
}

.alt-payment-list__item__link {
  display: block;
  padding: 1.2857142857em 0;
  min-width: 100%
}

@media (min-width: 750px) {
  .alt-payment-list__item__link {
      padding: 0.8571428571em 0;
      min-width: 10.7142857143em
  }
}

.alt-payment-list__item__logo {
  display: block;
  margin: 0 auto;
  width: auto;
  height: 1.2857142857em
}

.alt-payment-list__item--amazon {
  background: #fad676
}

.alt-payment-list__item--amazon:hover {
  background-color: #f9ca4f
}

.no-js .alt-payment-list__item--amazon {
  display: none
}

.alt-payment-list__item--amazon .alt-payment-list__item__logo {
  -webkit-transform: translateY(0.2857142857em);
  transform: translateY(0.2857142857em);
  width: 6.5714285714em;
  height: 1.2857142857em
}

.alt-payment-list-amazon-button-image {
  max-height: none !important;
  opacity: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.alt-payment-list__item--paypal {
  background-color: #ffc520
}

.alt-payment-list__item--paypal:hover {
  background-color: #f6b600
}

.alt-payment-list__item--paypalv4 .alt-payment-list__item__link {
  padding: 0 !important;
  margin: 0 !important;
  vertical-align: top;
  line-height: 0 !important
}

.alt-payment-list__item--paypalv4 .alt-payment-list__item--paypal-btn--mobile {
  display: none
}

@media (max-width: 749px) {
  .alt-payment-list__item--paypalv4 .alt-payment-list__item--paypal-btn--desktop {
      display: none
  }

  .alt-payment-list__item--paypalv4 .alt-payment-list__item--paypal-btn--mobile {
      display: block
  }
}

.alt-payment-list__item--apple-pay {
  background-color: #000;
  font-family: var(--fmc-font--proxima-nova);
  font-weight: normal;
  text-transform: none;
  text-decoration: none;
  white-space: nowrap
}

.alt-payment-list__item--apple-pay .alt-payment-list__item__link {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  color: #fff
}

@media (min-width: 750px) {
  .alt-payment-list__item--apple-pay .alt-payment-list__item__link {
      padding: 0.8571428571em 0
  }
}

.alt-payment-list__item--apple-pay .alt-payment-list__item__link .alt-payment-list__item__logo {
  width: 3em;
  background: -webkit-named-image(apple-pay-logo-white) center center no-repeat;
  background-size: auto 100%
}

.google-pay-iframe-container {
  padding: 0
}

.gpay-iframe {
  border-radius: 4px;
  height: 54px;
  width: 100%;
  display: block
}

@media (min-width: 750px) {
  .gpay-iframe {
      height: 42px;
      width: 10.7142857143em
  }
}

.alt-payment-list__item--google-pay {
  height: 54px;
  display: none
}

@media (min-width: 750px) {
  .alt-payment-list__item--google-pay {
      height: 42px;
      width: 10.7142857143em
  }
}

.alt-payment-list__item--google-pay-visible {
  display: block
}

@media (min-width: 750px) {
  .alt-payment-list__item--google-pay-visible {
      display: inline-block
  }
}

.alternative-payment-separator {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 0.8571428571em;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  color: #737373;
  margin-top: 2.5em
}

@media (max-width: 749px) {
  .alternative-payment-separator {
      padding-bottom: 2em
  }
}

@media (min-width: 750px) and (max-width: 999px) {
  .alternative-payment-separator {
      margin-bottom: 2em
  }
}

.alternative-payment-separator::after,
.alternative-payment-separator::before {
  content: '';
  display: inline-block;
  height: 1px;
  background-color: #e6e6e6;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1
}

.alternative-payment-separator__content {
  display: inline-block;
  padding: 0 1em
}

@media (min-width: 750px) {
  .dynamic-checkout {
      margin-top: 1.5em
  }
}

.dynamic-checkout__title {
  color: #737373;
  font-size: 1em;
  font-weight: 600;
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  text-align: center
}

.dynamic-checkout__title::before,
.dynamic-checkout__title::after {
  content: '';
  border: 1px #e6e6e6 solid;
  border-bottom: 0;
  height: 0.5em;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 2em;
  -ms-flex: 1 0 2em;
  flex: 1 0 2em
}

.dynamic-checkout__title::before {
  border-right: 0;
  border-top-left-radius: 5px;
  margin-right: 1em
}

.dynamic-checkout__title::after {
  border-left: 0;
  border-top-right-radius: 5px;
  margin-left: 1em
}

.dynamic-checkout__content {
  border: 1px #e6e6e6 solid;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0.9285714286em 1.4285714286em 1.4285714286em
}

.dynamic-checkout__skeleton {
  display: none
}

.dynamic-checkout__buttons {
  display: block
}

.dynamic-checkout--loading .dynamic-checkout__skeleton {
  display: block
}

.dynamic-checkout--loading .dynamic-checkout__skeleton .placeholder-line {
  height: 3.8571428571em
}

@media (min-width: 750px) {
  .dynamic-checkout--loading .dynamic-checkout__skeleton {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center
  }

  .dynamic-checkout--loading .dynamic-checkout__skeleton .placeholder-line {
      max-width: 18.7142857143em;
      height: 3em;
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto
  }

  .dynamic-checkout--loading .dynamic-checkout__skeleton .placeholder-line+.placeholder-line {
      margin-top: 0;
      margin-left: 0.5714285714em
  }
}

.dynamic-checkout--loading .dynamic-checkout__buttons {
  display: none
}

.hidden {
  display: none !important
}

.visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip: rect(0 0 0 0);
  width: 2px;
  height: 2px;
  margin: -2px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap
}

@media (max-width: 749px) {
  .visually-hidden-on-mobile {
      border: 0;
      clip: rect(0, 0, 0, 0);
      clip: rect(0 0 0 0);
      width: 2px;
      height: 2px;
      margin: -2px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap
  }
}

@media (min-width: 1000px) {
  .visually-hidden-on-desktop {
      border: 0;
      clip: rect(0, 0, 0, 0);
      clip: rect(0 0 0 0);
      width: 2px;
      height: 2px;
      margin: -2px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap
  }
}

.visible-on-focus {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip: rect(0 0 0 0);
  width: 2px;
  height: 2px;
  margin: -2px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap
}

.visible-on-focus:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
  white-space: inherit
}

.no-js .shown-if-js {
  display: none
}

.js .hidden-if-js {
  display: none
}

.js .visually-hidden-if-js {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip: rect(0 0 0 0);
  width: 2px;
  height: 2px;
  margin: -2px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap
}

@media (max-width: 749px) {
  .hidden-on-mobile {
      display: none
  }
}

@media (min-width: 750px) {
  .shown-on-mobile {
      display: none
  }
}

.no-margin {
  margin: 0 !important
}

@media (min-width: 750px) and (max-width: 999px) {
  .hidden-on-tablet {
      display: none
  }
}

@media (max-width: 749px) and (min-width: 1000px) {
  .shown-on-tablet {
      display: none
  }
}

@media (min-width: 1000px) {
  .hidden-on-desktop {
      display: none
  }
}

@media (max-width: 999px) {
  .shown-on-desktop {
      display: none
  }
}

@media (min-width: 750px) {
  .sms-marketing-fieldset {
      padding-left: 1.75em
  }
}

.sms-marketing-disclaimer {
  margin-top: 0
}

.order-summary-toggle {
  background: #fafafa;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 1.25em 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  text-align: left;
  width: 100%
}

@media (min-width: 1000px) {
  .order-summary-toggle {
      display: none
  }
}

.order-summary-toggle__inner {
  zoom: 1
}

.order-summary-toggle__inner:after,
.order-summary-toggle__inner:before {
  content: "";
  display: table
}

.order-summary-toggle__inner:after {
  clear: both
}

.display-table .order-summary-toggle__inner {
  display: table;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%
}

.order-summary-toggle__icon-wrapper {
  vertical-align: middle;
  padding-right: 0.75em;
  white-space: nowrap
}

.display-table .order-summary-toggle__icon-wrapper {
  display: table-cell
}

.no-js .order-summary-toggle__icon-wrapper,
.no-display-table .order-summary-toggle__icon-wrapper {
  float: left
}

.order-summary-toggle__icon {
  fill: #2861a4;
  -webkit-transition: fill 0.2s ease-in-out;
  transition: fill 0.2s ease-in-out
}

.order-summary-toggle:hover .order-summary-toggle__icon,
.order-summary-toggle:focus .order-summary-toggle__icon {
  fill: #1e497b
}

.order-summary-toggle__text {
  color: #2861a4;
  vertical-align: middle;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  display: none
}

.order-summary-toggle:hover .order-summary-toggle__text,
.order-summary-toggle:focus .order-summary-toggle__text {
  color: #1e497b
}

.order-summary-toggle--show .order-summary-toggle__text--show,
.order-summary-toggle--hide .order-summary-toggle__text--hide {
  display: block
}

.display-table .order-summary-toggle--show .order-summary-toggle__text--show,
.display-table .order-summary-toggle--hide .order-summary-toggle__text--hide {
  display: table-cell;
  width: 100%
}

.display-table .order-summary-toggle--show .order-summary-toggle__text--show:only-child,
.display-table .order-summary-toggle--hide .order-summary-toggle__text--hide:only-child {
  display: block
}

.no-js .order-summary-toggle--show .order-summary-toggle__text--show,
.no-display-table .order-summary-toggle--show .order-summary-toggle__text--show,
.no-js .order-summary-toggle--hide .order-summary-toggle__text--hide,
.no-display-table .order-summary-toggle--hide .order-summary-toggle__text--hide {
  float: left
}

.order-summary-toggle__total-recap {
  vertical-align: middle;
  text-align: right;
  padding-left: 0.75em;
  white-space: nowrap
}

.display-table .order-summary-toggle__total-recap {
  display: table-cell
}

.no-js .order-summary-toggle__total-recap,
.no-display-table .order-summary-toggle__total-recap {
  float: right
}

.total-recap__original-price {
  font-size: 0.8571428571em;
  color: #737373;
  display: block
}

.total-recap__final-price {
  font-size: 1.2857142857em;
  line-height: 1em;
  color: #333333
}

.order-summary-toggle__dropdown {
  vertical-align: middle;
  -webkit-transition: fill 0.2s ease-in-out;
  transition: fill 0.2s ease-in-out;
  fill: #2861a4
}

.order-summary-toggle:hover .order-summary-toggle__dropdown,
.order-summary-toggle:focus .order-summary-toggle__dropdown {
  fill: #1e497b
}

.g-recaptcha {
  border-radius: 4px;
  display: inline-block
}

.field--error .g-recaptcha {
  border-color: #e22120;
  -webkit-box-shadow: 0 0 0 1px #e22120;
  box-shadow: 0 0 0 1px #e22120
}

.no-js .g-recaptcha {
  display: none
}

.g-recaptcha-nojs {
  max-width: 100%;
  width: 302px
}

.g-recaptcha-nojs__iframe {
  height: 423px;
  width: 100%
}

.g-recaptcha-nojs__input-wrapper {
  background: #f9f9f9;
  border: 1px #c1c1c1 solid;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 0.5em;
  padding: 0.75em
}

.g-recaptcha-nojs__input {
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 5em;
  padding: 0.4285714286em;
  resize: vertical;
  width: 100%
}

.field--error .g-recaptcha-nojs__input {
  border-color: #e22120;
  -webkit-box-shadow: 0 0 0 1px #e22120;
  box-shadow: 0 0 0 1px #e22120
}

.sp-modal__button-wrapper {
  position: relative;
  overflow: hidden;
  padding: 1px
}

.sp-modal-toggle {
  opacity: 0;
  bottom: 0;
  border-radius: 0 5px 5px 0;
  margin: 1px;
  padding: 0 1em;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  z-index: 1;
  -webkit-animation: sp-fade-in 0.7s forwards cubic-bezier(0.2, 0.9, 0.3, 1);
  animation: sp-fade-in 0.7s forwards cubic-bezier(0.2, 0.9, 0.3, 1)
}

@media (max-width: 749px) {
  .sp-modal-toggle {
      -webkit-animation-name: fade-in;
      animation-name: fade-in
  }
}

.sp-modal-toggle:hover .sp-modal-toggle__phone-wrapper::after {
  border-color: #081424
}

.sp-modal-toggle:focus {
  outline-offset: -3px;
  outline-color: -webkit-focus-ring-color;
  outline-width: 4px
}

.sp-modal-toggle__icon-wrapper {
  display: block;
  position: relative
}

.sp-modal-toggle__icon-wrapper .sp-modal-toggle__icon {
  -webkit-animation: wobble 100ms 250ms linear 6;
  animation: wobble 100ms 250ms linear 6
}

.sp-modal-toggle__icon-wrapper::after {
  content: '';
  position: absolute;
  top: 3px;
  right: 8px;
  height: 6px;
  width: 6px;
  background-color: #fff;
  border: 1px solid #2861a4;
  border-radius: 50%;
  -webkit-animation: scale-up-bounce 400ms 850ms both;
  animation: scale-up-bounce 400ms 850ms both
}

.has-sp-modal body {
  height: auto;
  position: relative;
  min-height: 100%
}

.sp-modal-backdrop {
  background-color: rgba(0, 0, 0, 0);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: visibility 1s cubic-bezier(0.2, 0.9, 0.3, 1), background-color 1s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: visibility 1s cubic-bezier(0.2, 0.9, 0.3, 1), background-color 1s cubic-bezier(0.2, 0.9, 0.3, 1);
  visibility: hidden;
  z-index: 999;
  overflow-y: scroll;
  -webkit-transform: scale(0);
  transform: scale(0)
}

.sp-modal-backdrop--visible {
  background-color: rgba(0, 0, 0, 0.18);
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1)
}

.sp-modal {
  color: #545454;
  opacity: 0;
  outline: none;
  position: absolute;
  -webkit-transform: scale(0.1) matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1);
  transform: scale(0.1) matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1);
  -webkit-transform-origin: 0% 15%;
  transform-origin: 0% 15%;
  -webkit-transition: opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), -webkit-transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), -webkit-transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), -webkit-transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  visibility: hidden
}

@media (max-width: 749px) {
  .sp-modal {
      left: 50%;
      top: 2em;
      -webkit-transform: scale(1) translateX(-50%);
      transform: scale(1) translateX(-50%);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      -webkit-transition: opacity 0.3s 0.3s cubic-bezier(0.2, 0.9, 0.3, 1);
      transition: opacity 0.3s 0.3s cubic-bezier(0.2, 0.9, 0.3, 1)
  }
}

.sp-modal-backdrop--visible .sp-modal {
  opacity: 1;
  -webkit-transform: scale(1) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform: scale(1) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  visibility: visible
}

@media (max-width: 749px) {
  .sp-modal-backdrop--visible .sp-modal {
      -webkit-transform: scale(1) translateX(-50%);
      transform: scale(1) translateX(-50%)
  }
}

.sp-modal--hidden .sp-modal {
  opacity: 0;
  -webkit-transition: all 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: all 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  -webkit-transform: scale(0.1) matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1);
  transform: scale(0.1) matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1)
}

@media (max-width: 749px) {
  .sp-modal--hidden .sp-modal {
      -webkit-transform: scale(1) translateX(-50%);
      transform: scale(1) translateX(-50%)
  }
}

.sp-modal::before {
  background: white;
  border-radius: 4px;
  content: '';
  display: block;
  height: 25px;
  left: 2px;
  margin-left: -8px;
  position: absolute;
  top: 1.8em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 25px
}

@media (max-width: 749px) {
  .sp-modal::before {
      display: none
  }
}

.sp-modal::after {
  -webkit-box-shadow: 0 19px 47px rgba(0, 0, 0, 0.35);
  box-shadow: 0 19px 47px rgba(0, 0, 0, 0.35);
  content: '';
  display: block;
  height: 100%;
  left: 5%;
  position: absolute;
  right: 5%;
  top: 0;
  width: 90%;
  z-index: -1
}

.sp-modal__frame {
  display: block;
  -webkit-transition: height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  max-width: initial
}

.sp-modal--top {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%
}

.sp-modal--top::before {
  left: 50%;
  bottom: -8px;
  margin-left: -12.5px;
  top: auto
}

.sp-modal--center {
  left: 50%;
  top: 50%;
  position: fixed
}

.sp-modal-backdrop--visible .sp-modal--center {
  -webkit-transition: opacity 0.3s 0.3s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: opacity 0.3s 0.3s cubic-bezier(0.2, 0.9, 0.3, 1);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-left: 0
}

.sp-modal--center::before {
  display: none
}

.sp-modal--bottom-right,
.sp-modal--bottom-left {
  -webkit-transform-origin: 95% 0%;
  transform-origin: 95% 0%
}

.sp-modal--bottom-right::before,
.sp-modal--bottom-left::before {
  left: auto;
  margin-left: -8px;
  right: 7px;
  top: -6px
}

.sp-modal--bottom-right {
  -webkit-transform-origin: 5% 0%;
  transform-origin: 5% 0%
}

.sp-modal__content {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  z-index: 1
}

.sp-modal__close {
  position: absolute;
  z-index: 2;
  top: 1em;
  right: 1em;
  height: 1em;
  width: 1em;
  color: #737373;
  -webkit-transition: color 0.3s cubic-bezier(0.3, 0, 0, 1);
  transition: color 0.3s cubic-bezier(0.3, 0, 0, 1)
}

.sp-modal__close:focus,
.sp-modal__close:hover,
.sp-modal__close:active {
  color: #545454
}

@-webkit-keyframes sp-fade-in {
  0% {
      opacity: 0;
      -webkit-transform: translateX(0.5em);
      transform: translateX(0.5em);
      visibility: hidden
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
      visibility: visible
  }
}

@keyframes sp-fade-in {
  0% {
      opacity: 0;
      -webkit-transform: translateX(0.5em);
      transform: translateX(0.5em);
      visibility: hidden
  }

  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
      visibility: visible
  }
}

@-webkit-keyframes skeletonShimmerAnimation {
  0% {
      opacity: 0.45
  }

  100% {
      opacity: 0.9
  }
}

@keyframes skeletonShimmerAnimation {
  0% {
      opacity: 0.45
  }

  100% {
      opacity: 0.9
  }
}

.content .skeleton-loading-text,
.order-summary-toggle .skeleton-loading-text {
  position: relative;
  display: block;
  float: right;
  background-color: rgba(160, 170, 184, 0.16);
  border-radius: 4px;
  -webkit-animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  will-change: opacity;
  width: 5em;
  height: 1.3em
}

.content .skeleton-loading-text--lg,
.order-summary-toggle .skeleton-loading-text--lg {
  position: relative;
  display: block;
  float: right;
  background-color: rgba(160, 170, 184, 0.16);
  border-radius: 4px;
  -webkit-animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  will-change: opacity;
  height: 1.7142857143em;
  width: 8em
}

.content .skeleton-loading-text--sm,
.order-summary-toggle .skeleton-loading-text--sm {
  position: relative;
  display: block;
  float: right;
  background-color: rgba(160, 170, 184, 0.16);
  border-radius: 4px;
  -webkit-animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  will-change: opacity;
  height: 0.8571428571em;
  width: 5em
}

.content .skeleton-loading-text--left,
.order-summary-toggle .skeleton-loading-text--left {
  position: relative;
  display: block;
  float: right;
  background-color: rgba(160, 170, 184, 0.16);
  border-radius: 4px;
  -webkit-animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  will-change: opacity;
  height: 1.3em;
  width: 5em;
  float: left
}

.content .skeleton-loading-text--rate,
.order-summary-toggle .skeleton-loading-text--rate {
  position: relative;
  display: block;
  float: right;
  background-color: rgba(160, 170, 184, 0.16);
  border-radius: 4px;
  -webkit-animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  will-change: opacity;
  height: 1.45em;
  width: 12em;
  float: none
}

.content .skeleton-loading-text--link,
.order-summary-toggle .skeleton-loading-text--link {
  position: relative;
  display: block;
  float: right;
  background-color: rgba(160, 170, 184, 0.16);
  border-radius: 4px;
  -webkit-animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  will-change: opacity;
  height: 1.5em;
  width: 8em;
  float: none
}

.content .skeleton-loading-text--inline,
.order-summary-toggle .skeleton-loading-text--inline {
  position: relative;
  display: block;
  float: right;
  background-color: rgba(160, 170, 184, 0.16);
  border-radius: 4px;
  -webkit-animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  animation: skeletonShimmerAnimation 800ms linear infinite alternate;
  will-change: opacity;
  height: 1.3em;
  width: 5em;
  position: absolute;
  margin-left: 0.25em;
  display: inline-block
}

.disable-link-while-loading.loading {
  pointer-events: none;
  color: #a0aab8
}

.review-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.review-block~.review-block {
  margin-top: 0.8571428571em;
  padding-top: 0.8571428571em;
  border-top: 1px solid #e6e6e6
}

.review-block__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1
}

@media (max-width: 749px) {
  .review-block__inner {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
  }
}

.review-block__label {
  color: #737373;
  padding-right: 1.1428571429em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 5em;
  -ms-flex: 0 1 5em;
  flex: 0 1 5em
}

@media (max-width: 749px) {
  .review-block__label {
      padding-bottom: 0.2857142857em
  }
}

.review-block__content {
  -webkit-box-flex: 5;
  -webkit-flex: 5;
  -ms-flex: 5;
  flex: 5;
  color: #333333;
  padding-right: 1.1428571429em;
  overflow-wrap: break-word;
}

@media (max-width: 749px) {
  .review-block__content {
      width: 100%;
      -webkit-box-flex: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      overflow-wrap: break-word;
  }
}

.review-block__link {
  max-width: 10em;
  font-size: 0.8571428571em
}
.shipping-method-container button .review-block__link:hover {
  color: #f26148;
}
.review-block__link-edit{
  max-width: 10em;
  //width: 31px;
  height:24px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #0562D2 !important;
  font-weight: 400;
}
.review-block__link-edit a{
  color: #0562D2 !important;
}

.review-block__billing-address {
  margin-top: 0.25em
}

.flag-selector {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  -webkit-transition: all 0.2s cubic-bezier(0, 0.34, 0.1, 0.98);
  transition: all 0.2s cubic-bezier(0, 0.34, 0.1, 0.98);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0 0.7142857143rem
}

.no-js .flag-selector,
.no-anyflexbox .flag-selector {
  display: table
}

.flag-selector::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -10px;
  height: 20px;
  border-left: 1px solid transparent
}

.flag-selector--visible::after {
  border-left-color: #d9d9d9;
  -webkit-animation: scale-up 0.1s cubic-bezier(0, 0.34, 0.1, 0.98) forwards;
  animation: scale-up 0.1s cubic-bezier(0, 0.34, 0.1, 0.98) forwards
}

.flag-selector--hidden {
  display: none
}

.flag-selector--focus {
  background: rgba(40, 97, 164, 0.25)
}

.flag-selector--focus::after {
  display: none
}

.flag-selector__caret {
  display: block;
  margin-left: 0.5em
}

.no-js .flag-selector__caret,
.no-anyflexbox .flag-selector__caret {
  display: table-cell;
  vertical-align: middle
}

.flag-selector--visible .flag-selector__caret {
  -webkit-animation: fade-in 0.2s cubic-bezier(0, 0.34, 0.1, 0.98) forwards;
  animation: fade-in 0.2s cubic-bezier(0, 0.34, 0.1, 0.98) forwards
}

.flag-selector--focus .flag-selector__caret {
  fill: #2861a4
}

.flag-selector__icon {
  height: 20px;
  width: 27px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-filter: saturate(1.3) hue-rotate(-5deg);
  filter: saturate(1.3) hue-rotate(-5deg);
  opacity: 0;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out
}

.no-js .flag-selector__icon,
.no-anyflexbox .flag-selector__icon {
  display: table-cell;
  vertical-align: middle
}

.flag-selector--visible .flag-selector__icon {
  -webkit-animation: scale-up-bounce 0.2s cubic-bezier(0, 0.34, 0.1, 0.98) forwards;
  animation: scale-up-bounce 0.2s cubic-bezier(0, 0.34, 0.1, 0.98) forwards
}

.flag-selector--hidden .flag-selector__icon {
  -webkit-animation: scale-down-bounce 0.2s cubic-bezier(0.57, 0.31, 0.42, 0.91) forwards;
  animation: scale-down-bounce 0.2s cubic-bezier(0.57, 0.31, 0.42, 0.91) forwards
}

.flag-selector__select {
  background-color: inherit;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0
}

.placeholder-line {
  height: 1em;
  border-radius: 4px;
  border: 1px solid transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #e6e6e6
}

.placeholder-line+.placeholder-line {
  margin-top: 0.5em
}

.placeholder-line--animated {
  -webkit-animation: 1.5s linear 0s infinite normal forwards running loading;
  animation: 1.5s linear 0s infinite normal forwards running loading;
  background-repeat: no-repeat;
  background-size: 200px 100%
}

.main .placeholder-line--animated {
  background-image: -webkit-gradient(linear, right top, left top, from(#e6e6e6), color-stop(50%, #cdcdcd), to(#e6e6e6));
  background-image: linear-gradient(to left, #e6e6e6 0%, #cdcdcd 50%, #e6e6e6 100%)
}

.sidebar .placeholder-line--animated {
  background-image: -webkit-gradient(linear, right top, left top, from(#2f4664), color-stop(50%, #1f2e41), to(#2f4664));
  background-image: linear-gradient(to left, #2f4664 0%, #1f2e41 50%, #2f4664 100%)
}

.content-box .placeholder-line--animated {
  background-image: -webkit-gradient(linear, right top, left top, from(#e6e6e6), color-stop(50%, #cdcdcd), to(#e6e6e6));
  background-image: linear-gradient(to left, #e6e6e6 0%, #cdcdcd 50%, #e6e6e6 100%)
}

.default-background .placeholder-line--animated {
  background-image: -webkit-gradient(linear, right top, left top, from(#e6e6e6), color-stop(50%, #cdcdcd), to(#e6e6e6));
  background-image: linear-gradient(to left, #e6e6e6 0%, #cdcdcd 50%, #e6e6e6 100%)
}

.placeholder-line--loose-vertical+.placeholder-line--loose-vertical {
  margin-top: 1em
}

.placeholder-line--40 {
  width: 40%
}

.placeholder-line--45 {
  width: 45%
}

.placeholder-line--50 {
  width: 50%
}

.placeholder-line--60 {
  width: 60%
}

.placeholder-line--70 {
  width: 70%
}

.placeholder-line--75 {
  width: 75%
}

.placeholder-line--90 {
  width: 90%
}

.placeholder-line--100 {
  width: 100%
}

@-webkit-keyframes loading {
  0% {
      background-position: -200px 0
  }

  100% {
      background-position: calc(100% + 200px) 0
  }
}

@keyframes loading {
  0% {
      background-position: -200px 0
  }

  100% {
      background-position: calc(100% + 200px) 0
  }
}

.skip-to-content {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: white;
  text-align: center;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip: rect(1px 1px 1px 1px);
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
  margin: -1px
}

.skip-to-content:focus {
  margin: 0;
  padding: 1em;
  clip: auto;
  width: 100%;
  height: auto;
  overflow: visible
}

.tags-list {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.tags-list .tag {
  margin-top: 0.8571428571em;
  margin-right: 0.8571428571em
}

.tags-list .tag:last-child {
  margin-right: 0
}

@media (min-width: 1000px) {
  .tags-list {
      float: left
  }
}

.tag {
  border-radius: 4px;
  background-color: rgba(160, 170, 184, 0.11);
  color: #a0aab8;
  font-size: 0.8571428571em;
  padding: 0.8571428571em;
  overflow: hidden
}

.tag__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%
}

.tag__text {
  color: white;
  font-size: 1.1428571429em;
  font-weight: 600;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap
}

.tag__button {
  margin-left: 0.8571428571em
}

.tag__button .icon-svg {
  stroke: rgba(160, 170, 184, 0.9)
}

.tag__button:hover .icon-svg,
.tag__button:focus .icon-svg {
  stroke: white
}

.list {
  padding-left: 1.5em;
  list-style: disc outside;
  line-height: 1.5em
}

.list+.list {
  margin-top: 1.5em
}

.list__item>.list {
  margin-top: 0.5em
}

.list__item {
  margin-top: 0.5em
}

.list__item:first-child {
  margin-top: 0
}

.tabs__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.tabs__item {
  width: 100%
}

.tabs__button {
  width: 100%;
  height: 100%;
  padding: 1.1428571429em;
  -webkit-box-shadow: inset 0 -3px 0 0 #d9d9d9;
  box-shadow: inset 0 -3px 0 0 #d9d9d9;
  color: #737373;
  font-weight: 600;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center
}

.tabs__button:hover {
  -webkit-box-shadow: inset 0 -3px 0 0 #919191;
  box-shadow: inset 0 -3px 0 0 #919191;
  color: #545454
}

.tabs__button>* {
  pointer-events: none
}

.tabs__button--active,
.tabs__button--active:hover {
  -webkit-box-shadow: inset 0 -3px 0 0 #2861a4;
  box-shadow: inset 0 -3px 0 0 #2861a4;
  color: #2861a4
}

.button-group__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.button-group__list .button-group__item:first-of-type .button-group__button {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px
}

.button-group__list .button-group__item:last-of-type .button-group__button {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px
}

.button-group__list--bordered .button-group__item {
  position: relative
}

.button-group__list--bordered .button-group__item:not(:first-of-type) {
  border-left: 1px #d9d9d9 solid
}

.button-group__item {
  width: 100%
}

.button-group__button {
  width: 100%;
  height: 100%;
  padding: 1.1428571429em;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #545454;
  font-weight: 600;
  -webkit-transition: all 0.2s cubic-bezier(0.3, 0, 0, 1);
  transition: all 0.2s cubic-bezier(0.3, 0, 0, 1);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-style: solid;
  border-color: transparent
}

.button-group__button>* {
  pointer-events: none
}

.button-group__button--active {
  color: #2861a4;
  border-style: solid;
  border-color: #2861a4;
  border-width: 2px
}

.button-group__button--subtitled {
  display: block;
  padding-top: 0.6em;
  padding-bottom: 0.4em
}

.has-qr-modal body {
  height: auto;
  position: relative;
  min-height: 100%
}

.qr-modal-backdrop {
  background-color: rgba(0, 0, 0, 0);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: visibility 1s cubic-bezier(0.2, 0.9, 0.3, 1), background-color 1s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: visibility 1s cubic-bezier(0.2, 0.9, 0.3, 1), background-color 1s cubic-bezier(0.2, 0.9, 0.3, 1);
  visibility: hidden;
  z-index: 999;
  overflow: visible;
  -webkit-transform: scale(0);
  transform: scale(0)
}

.qr-modal-backdrop--visible {
  background-color: rgba(0, 0, 0, 0.18);
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1)
}

.qr-modal {
  color: #545454;
  opacity: 0;
  outline: none;
  position: absolute;
  -webkit-transform: scale(0.1) matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1);
  transform: scale(0.1) matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1);
  -webkit-transform-origin: 0% 15%;
  transform-origin: 0% 15%;
  -webkit-transition: opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), -webkit-transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), -webkit-transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), -webkit-transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  visibility: hidden
}

.qr-modal-backdrop--visible .qr-modal {
  opacity: 1;
  -webkit-transform: scale(1) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform: scale(1) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  visibility: visible
}

.qr-modal--hidden .qr-modal {
  opacity: 0;
  -webkit-transition: all 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: all 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  -webkit-transform: scale(0.1) matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1);
  transform: scale(0.1) matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1)
}

.qr-modal::before {
  background: #dddddd;
  background: linear-gradient(45deg, white 0%, #ddd 50%);
  border-radius: 4px;
  content: '';
  display: block;
  height: 25px;
  left: 2px;
  margin-left: -8px;
  position: absolute;
  top: 1.8em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 25px
}

.qr-modal::after {
  -webkit-box-shadow: 0 19px 47px rgba(0, 0, 0, 0.35);
  box-shadow: 0 19px 47px rgba(0, 0, 0, 0.35);
  content: '';
  display: block;
  height: 100%;
  left: 5%;
  position: absolute;
  right: 5%;
  top: 0;
  width: 90%;
  z-index: -1
}

.qr-modal--top {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%
}

.qr-modal--top::before {
  background: linear-gradient(135deg, white 0%, #ddd 50%);
  left: 50%;
  bottom: -8px;
  margin-left: -12.5px;
  top: auto
}

.qr-modal--center {
  left: 50%;
  top: 50%;
  position: fixed
}

.qr-modal-backdrop--visible .qr-modal--center {
  -webkit-transition: opacity 0.3s 0.3s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: opacity 0.3s 0.3s cubic-bezier(0.2, 0.9, 0.3, 1);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-left: 0
}

.qr-modal--center::before {
  display: none
}

.qr-modal--bottom-right,
.qr-modal--bottom-left {
  -webkit-transform-origin: 95% 0%;
  transform-origin: 95% 0%
}

.qr-modal--bottom-right::before,
.qr-modal--bottom-left::before {
  background: linear-gradient(135deg, white 0%, #ddd 50%);
  left: auto;
  margin-left: -8px;
  right: 7px;
  top: -6px
}

.qr-modal--bottom-right {
  -webkit-transform-origin: 5% 0%;
  transform-origin: 5% 0%
}

.qr-modal__content {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  z-index: 1
}

.qr-code-container {
  max-width: 14.2857142857em;
  font-size: 1em
}

.qr-code-section {
  padding: 1.1428571429em;
  font-size: 1em;
  line-height: 1.3em;
  color: #000
}

.qr-code-separator {
  margin: 0;
  border-top: none;
  color: #9b9b9b
}

.qr-code-title {
  margin-top: 1.1428571429em;
  font-size: 1em;
  font-weight: 600
}

.page--stock-problems.page--logo-main .breadcrumb,
.page--stock-problems.page--logo-sidebar .main__header,
.page--stock-problems.page--logo-banner .main__header {
  display: none
}

.stock-problem-table {
  border-bottom: 1px solid #e6e6e6
}

.exclamation-mark {
  color: #737373;
  fill: currentColor;
  stroke: currentColor
}

.exclamation-mark__circle {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 50% 50% 0;
  transform-origin: 50% 50% 0
}

.cssanimations .exclamation-mark__circle {
  stroke-dasharray: 151;
  stroke-dashoffset: 151;
  -webkit-animation: draw-stroke 0.6s 0.4s cubic-bezier(0.3, 0, 0, 1) forwards;
  animation: draw-stroke 0.6s 0.4s cubic-bezier(0.3, 0, 0, 1) forwards
}

.cssanimations .exclamation-mark__line {
  stroke-dasharray: 18;
  stroke-dashoffset: 18;
  -webkit-animation: draw-stroke 0.3s 0.8s cubic-bezier(0.3, 0, 0, 1) forwards;
  animation: draw-stroke 0.3s 0.8s cubic-bezier(0.3, 0, 0, 1) forwards
}

.exclamation-mark__dot {
  -webkit-transform-origin: 50% 50% 0;
  transform-origin: 50% 50% 0
}

.cssanimations .exclamation-mark__dot {
  -webkit-animation: scale-up 0.3s 1.01s cubic-bezier(0.3, 0, 0, 1) both;
  animation: scale-up 0.3s 1.01s cubic-bezier(0.3, 0, 0, 1) both
}

.os-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0
}

@media (min-width: 750px) {
  .os-header {
      margin: 0 0 -0.5em
  }
}

.os-header__heading {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1
}

.os-order-number {
  display: block;
  margin-bottom: 0.25em
}

.os-header__title {
  font-size: 1.5714285714em
}

.os-header__hanging-icon {
  margin-right: 0.75em;
  stroke: #2861a4
}

@media (min-width: 750px) and (max-width: 999px) {
  .os-header__hanging-icon {
      position: absolute;
      right: 100%;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      margin-right: 1.5em
  }
}

@media (min-width: 1300px) {
  .main {
      &.order-confirmation-main {
          .os-header__hanging-icon {
              stroke: none;
              top: 25%;
              margin-right: 2.5rem;
          }
      }
  }

  .os-header__hanging-icon {
      position: absolute;
      right: 100%;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      margin-right: 1.5em
  }
}

.map {
  overflow: hidden
}

.content-box__row:first-child .map {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px
}

.map__iframe {
  display: block;
  height: 200px;
  width: calc(100% + 1px);
  border: 0;
  z-index: 0
}

.map__placeholder {
  height: 200px;
  width: 100%;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.gm-style .gm-style-iw {
  color: #333333;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 14px;
  line-height: 1.5em;
  font-weight: 400;
  padding-top: 5px;
  text-align: center;
  width: 100%
}

.gm-style .gm-style-iw+div {
  display: none
}

.os-timeline {
  margin: 0;
  padding: 0
}

.os-timeline-step__title {
  display: block;
  color: #737373
}

.os-timeline-step__date {
  display: none
}

.os-timeline-step--selected .os-timeline-step__icon {
  fill: #2861a4
}

.os-timeline-step--selected .os-timeline-step__title {
  color: #2861a4;
  font-weight: 600
}

.os-timeline-step--selected .os-timeline-step__date {
  display: block;
  font-size: 0.8571428571em;
  color: #737373
}

@media (max-width: 749px) {
  .os-timeline-step:not(.os-timeline-step--current) {
      display: none
  }

  .os-timeline-step {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center
  }

  .os-timeline-step__title {
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1
  }
}

@media (min-width: 750px) {
  .os-timeline {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      overflow: hidden
  }

  .os-timeline-step {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
      max-width: 25%;
      position: relative;
      padding-top: 20px
  }

  .os-timeline-step:before,
  .os-timeline-step:after {
      content: "";
      display: block;
      height: 2px;
      position: absolute;
      top: 8.5px;
      right: 50%
  }

  .os-timeline-step:before {
      background: #e6e6e6;
      width: 2000px
  }

  .os-timeline-step:first-child:after {
      background: #fff;
      left: 0;
      z-index: 2
  }

  .os-timeline-step__icon {
      fill: #b3b3b3;
      background: #fff;
      background: #fff;
      position: absolute;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      z-index: 3
  }

  .os-timeline-step__title {
      display: block;
      color: #737373
  }

  .os-timeline-step__date {
      display: none
  }

  .os-timeline-step--selected:before {
      background: #2861a4;
      z-index: 1
  }

  .os-timeline-step--selected .os-timeline-step__icon {
      fill: #2861a4
  }

  .os-timeline-step--selected .os-timeline-step__title {
      color: #2861a4;
      font-weight: 600
  }

  .os-timeline-step--selected .os-timeline-step__date {
      display: block
  }
}

.os-step__info {
  margin-top: 1em
}

.os-step__info-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 0.75em
}

.os-step__special-description+.os-step__special-description {
  border-top: 1px #e6e6e6 solid;
  margin-top: 1.5em;
  padding-top: 1.5em
}

.os-step__shop-logo {
  width: 36px
}

.os-step__shop-pay-disclaimer {
  margin-bottom: 12px
}

.os-step__shop-pay-disclaimer-icon {
  width: 58px;
  height: 14px
}

.os-step__shop-pay-disclaimer-icon path {
  fill: #000
}

.tracking-info {
  border-top: 1px solid #e6e6e6;
  margin-top: 1.1428571429em;
  padding-top: 1.1428571429em
}

.tracking-info__number {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  color: #737373
}

.shipment-information {
  padding: 1em
}

.shipment-information__items td {
  padding-top: 1em
}

.shipment-information__item:first-child td {
  padding-top: 0
}

.shipment-information__image {
  width: 2.5em
}

.shipment-information__image .product-thumbnail {
  width: 2.5em;
  height: 2.5em;
  border-right: 6px
}

.shipment-information__image .product-thumbnail__wrapper {
  border-right: 6px
}

.shipment-information__image .product-thumbnail__quantity {
  font-size: 11px;
  top: -0.65em;
  padding: 0 0.55em
}

.arrive-phone-description {
  margin: 8px 0 6px;
  color: #545454;
  font-size: 0.85714em;
  line-height: 135%
}

.arrive-button {
  font-weight: 600
}

.arrive-button__regular {
  font-weight: 400
}

.arrive-button__logo {
  width: 35px;
  height: 14px
}


/* This stylesheet generated by Transfonter (https://transfonter.org) on October 31, 2017 8:31 PM */

.order-summary__emphasis,
input,
select {
  font-size: 16px;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal !important;
  font-weight: 300;
  letter-spacing: 1.6px;
}

.product__price .order-summary__emphasis,
.payment-due__price,
.total-line__price .order-summary__emphasis {
  font-weight: 400;
}

body .section__title {
  font-family: var(--fmc-font--proxima-nova);
}

body .btn__content {
  font-weight: 500;
}


/* Header Banner - Only Shows On Mobile */

.banner .logo__text {
  /* color: #4d4d4d;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  line-height: normal; */
  display: none;
}


/* Header */

.main .main__header {
  padding-bottom: 20px;
}

.main__header .logo__text {
  /* color: #4d4d4d;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  line-height: normal; */
  display: none;
}


/* Thankyou Hero */

.thankyou-hero {  
  padding: 60px 30px 80px 0px;
  background-image: url("https://storage.googleapis.com/hclcommerce2-b2c-prod-gcs-ext/B2C/lincoln/HomePage/CollectionPage_HeroPanel_SilverLincolnWaterMountain_D.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; 
   
}

.thankyou-hero__heading {
  color: #fff;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 40px;
  line-height: 58px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-align: left;
}
@media (max-width: 768px) {
  .thankyou-hero__heading {
    font-size: 34px;
    line-height: 42px;
  }
}


/* Thankyou Offer Image */

.thankyou-offer-image {
  margin-top: 1em;
  width: 100%;
}


/* Header - Main Content */

.main__content .section__header .os-header__heading .os-order-number {
  color: #636363;
  font-style: normal;
  font-size: 1.2857142857em;
  line-height: 1.3em;
  letter-spacing: normal;
  font-weight: 500;
  letter-spacing: 1.6px;
}

.main__content .section__header .os-header__heading .os-header__title {
  color: #4d4d4d;
  font-size: 1.2857142857em;
  line-height: 1.3em;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
}
.main__content .section__header .print-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 1px;
  height: 40px;
  padding-left: 30px;
  padding-right: 30px;
  color: #fff;
  background: #324047;
  /*border-radius: 20px;*/
}
@media (max-width: 450px) {
  .main__content .section__header .print-button {
    width: 100%;
    margin-top: 15px;
  }
}

/* Checkout Messages - Ineglible State */

.checkout-message  {
  // margin-top: 15px;
  padding: 8px;
  background-color: #f2f2f2;
  color: #fff;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  width: 90%;
  @media (max-width : 767px) {
      width: 100%;
  }
  @media (max-width : 360px) {
      padding: 5px 15px;
      font-size: 13px;
  }
}
.billingcheckbox {
  .MuiFormControlLabel-label {
    font-size: 15px;
  }
  svg {
    width: 1.5em;
    height: 1.5em;
  }
}

.checkoutAddress .checkout-message p {
  color: #000 !important;
  @media (max-width: 999px) {
    font-size: 14px;
  }
}
.disclaimer_box {
  margin-top: 2rem;
  background: #ffffff;
  padding: 18px;
  color: #323232;
  border-radius: 7px;
  h2 {
    margin-bottom: 0.5rem;
    font-size: 14px;
    color: #323232;
  }
  p {
    font-size: 12px;
    letter-spacing: 0.08em;
  }
}
.checkout-message a {
  color: #000;
  text-decoration: underline;
}
.checkout-message p {
  padding: 10px;
}
.checkout-actions button {
  width: auto;
  margin-left: 0.8571428571em;
  white-space: nowrap;
  padding: 20px;
  /* padding-bottom: 0; */
  font-family: var(--fmc-font--proxima-nova);
  font-size: 15px;
  height: 50px;
  font-weight: 500;
  position: relative;
  transition: 0.2s all ease-in-out;
  @media (max-width: 999px) {
    font-size: 13px;
    padding: 10px;
  }
}
.checkout-actions button:hover {
  background-color: #324047;
}
.checkout-actions button:hover::before {
  transition: 0.2s all ease-in-out;
  content: " ";
  display: block;
  width: 100%;
  position: absolute;
  border-bottom: 3px solid #f26148;
  bottom: 0;
  left: 0;
  opacity: 1;
}
.continue-button{
  padding: 1em;
  @media (max-width : 360px) {
      padding: 0;
  }
}

/* Checkout Terms Checkbox */

.checkout-terms-checkbox__section {}

.checkout-terms-checkbox__content {
  padding: 20px 40px;
  border-bottom: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  @media (max-width: 999px) {
    padding: 20px 0px;
  }
}
.shippingtitle {
  text-transform:none;
  text-align: start;
  font-size: 16px;
  color: #000;
  font-family: var(--fmc-font--proxima-nova) !important;
  font-weight: 600 !important;
}
.checkout-terms-checkbox__label {
  display: flex;
  align-items: center;
}

.checkout-terms-checkbox__label:not(:last-child) {
  margin-bottom: 15px;
}

.checkout-terms-checkbox__input-wrapper {
  margin-right: 20px;
}

.checkout-terms-checkbox__input {}

.checkout-terms-checkbox__content {}

.checkout-terms-checkbox__text {
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1px;
}

#continue_button[data-disabled-by-terms] {
  background: #939393;
}


/* Disable Pickup Order Shipping Address Fields */

.main--shipping-fields-disabled [data-address-field="address1"],
.main--shipping-fields-disabled [data-address-field="address2"],
.main--shipping-fields-disabled [data-address-field="city"],
.main--shipping-fields-disabled [data-address-field="country"],
.main--shipping-fields-disabled [data-address-field="province"],
.main--shipping-fields-disabled [data-address-field="zip"],
.main--shipping-fields-disabled [data-address-field="phone"] {
  opacity: 1;
  pointer-events: none;
}


/* The Popup Modal (background) */

.fma-modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 100;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}


/* Modal Content/Box */

.fma-modal-content {
  margin: 15% auto;
  /* centered */
  width: 867px;
  height: 515px;
  position: relative;
  overflow: hidden;
}


/* The Close Button */

.fma-close {
  font-weight: 200;
  font-size: 48px;
  position: absolute;
  top: 8px;
  right: 24px;
  color: #fff;
  z-index: 1;
}

.fma-close:hover,
.fma-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.fma-popup_image {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  object-fit: cover;
}

.fma-popup-content {
  position: absolute;
  top: 0%;
  height: 100%;
  padding: 4em;
  text-align: center;
  color: #fff;
  background-color: rgba(16, 43, 78, .8);
}

.fma-header {
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 3px;
  line-height: 34px;
  text-transform: uppercase;
  margin-bottom: 1em;
}

.fma-header span {
  font-weight: 500;
}

.fma-description {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 26px;
  margin-bottom: 1em;
}

.fma-description~.fma-description {
  display: none;
  font-size: 14px;
  letter-spacing: .88px;
}

.fma-description~.fma-description br {
  margin: 1em;
}

.fma-info {
  font-size: 14px;
  font-weight: 200;
  font-style: normal;
  letter-spacing: .88px;
  line-height: 26px;
  margin-bottom: 4em;
}

.fma-info span {
  text-decoration: underline;
}

.fma-login {
  background-color: #fff;
  color: #102b4e;
  border-radius: 20px;
  font-size: 18px;
  height: 40px;
  min-width: 165px
}

.fma-login:hover {
  background-color: #102b4e !important;
  color: #fff !important;
}

.fma-login-mobile {
  display: none;
  margin-bottom: 1em;
}

.fma-read-more {
  display: none;
  text-decoration: underline;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 24px;
  color: #fff;
}

@media screen and (max-width: 945px) {
  .fma-modal-content {
      width: 90%;
  }

  .fma-close {
      font-size: 24px;
      right: 12px;
  }

  .fma-popup-content {
      padding: 2em 1em;
  }
}

@media screen and (max-width: 710px) {
  .fma-modal-content {
      height: 350px;
  }

  .fma-description {
      display: none;
  }

  .fma-description~.fma-description {
      display: block;
  }

  .fma-info {
      display: none;
      margin-bottom: 1em;
  }

  .fma-login {
      display: none;
  }

  .fma-login-mobile {
      display: inline;
  }

  .fma-read-more {
      display: inline;
  }
}

@media screen and (max-width: 555px) {
  .fma-modal-content {
      height: 460px;
  }
}

@media screen and (max-width: 472px) {
  .fma-modal-content {
      height: 480px;
  }
}

@media screen and (max-width: 328px) {
  .fma-modal-content {
      height: 540px;
  }
}

.fma-open {
  height: 692px;
}

@media screen and (max-width: 400px) {
  .fma-open {
      height: 790px;
  }
}

@media screen and (max-width: 374px) {
  .fma-open {
      height: 970px;
  }
}

@media screen and (max-width: 296px) {
  .fma-open {
      height: 90vh;
  }
}

.fma-hide {
  display: block;
}

.reduction-code:nth-child(2) {
  display: none;
}


@media (max-width: 767px) {
  .a {
      flex-direction: column;
      padding : 0px;
  }

  .a div:first-child {
      // padding-left: 0;
      margin-top: 20px;
  }

  .a div:last-child {
      padding-left: 0;
  }

  .small-text {
      font-size: 10px;
  }
}

// .shipping-padding {
//     padding-left: 4em;
// }
@media (min-width: 750px) {
  .shipping-padding {
      margin: 0;
      padding-left: 0;
  }
}

.nopayment {
  background: url(/themes/icons/nopayment.svg) center right no-repeat;
  height: 80px;
  width: 151px;
}

.contact-block-section{
  font-weight: 400;
  line-height: 24px;
  color: #000;
}

.shipping-address-section,
.billing-address-section {
display: inline-block;
vertical-align: top;
width: 200px;
margin-right: 10px;
font-weight: 400;
line-height: 24px;
color: #000;
}
.shipping-heading,
.billing-heading
{
  margin-bottom: 5px; 
}
.address-line{
  word-wrap: break-word;
}
.viewStyleCardActions{
  position: absolute;
  margin-right: auto;
  margin-left: auto;
  right: 20px;
  top: 20px;
} 

.discounts-container {
  @media (min-width:1270px) and (max-width:1600px){
    width: 100%;
  }
   
}
.nodiscounts-container { 
  @media (min-width:1270px) and (max-width:1380px){
    width: 100%;
  }
}

.text-container-orderinfo {
  margin-left: -16px;
}

.order-contact-information {
  padding: 10px 0px 10px 0px;
  line-height: 25px;
  margin-bottom: 20px;

  .heading-3 {
      font-size: 24px;
      font-weight: 200;
      text-transform: inherit;
  }
}

.order-contact-information p, .order-payment-info p, p.ship-pickup-address-title{
  color: #cfd5dc; 
}
.order-payment-info p {
  font-size: 16px;
}
.order-payment-info {
  padding: 10px 0px 10px 0px;
  line-height: 25px;

  .heading-3 {
      font-size: 24px;
      font-weight: 100;
      text-transform: inherit;
  }

  .credit-card {
      font-size: 20px;
      font-weight: 400;
      margin: 20px 0px 5px 0px;
      color: #cfd5dc;
  }
  

  .order-billing-info {
      margin-top: 20px;
  }

  .address {
      margin-top: 10px;
      line-height: 25px;
  }
}

.order-shipping-pickup-info {
  padding: 10px 0px 10px 0px;
  line-height: 25px;

  .heading-3 {
      font-size: 24px;
      font-weight: 200;
      text-transform: inherit;
  }

  .address {
      margin-top: 10px;
      line-height: 25px;
  }

  .ship-pickup-address-title {
      font-size: 20px;
      font-weight: 400;
      margin-top: -5px;
  }
}

.os-step__description {
  font-size: 18px;
  margin-top: 15px;
  text-align: left;
}

@media (min-width:959px) and (max-width:1024px) {
  .checkout-shipping-container .main-content-input {
  padding: 1em !important;
  }
}


  /***********Order-review-container*************/

  .breabrums-list {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    @media (max-width: 999px) {
      display: inline-block;
      font-size: 13px;
    }
    .full-height {
      svg {
        margin: 0 7px;
        width: 0.8em;
        height: 0.8em;
        margin-top: 2px;
        font-size: 21px;
        @media (max-width: 999px) {
          position: relative;
          top: 4px;
          margin: 0px 0px;
        }
      }
      .labelClass-Shipping {
        label {
          &.MuiInputLabel-outlined {
            width: 100%;

            .field__label1 {
              margin: 0;
              top: 28px;
              left: 15px;
              font-weight: 300;
              font-size: 17px;
              position: absolute;
              -webkit-transition: all 0.2s ease-out;
              transition: all 0.2s ease-out;
            }
          }
          &.MuiInputLabel-outlined.MuiInputLabel-shrink {
            .field__label1 {
              top: 18px;
              z-index: 1;
              font-size: 14px;
              color: #737373;
              font-weight: 300;
            }
          }
        }
        .MuiOutlinedInput-root {
          padding: 0;

          input {
            height: 100%;
            font-size: 16px;
            font-weight: 300;
            padding: 25px 15px 10px;
          }
          fieldset {
            font-size: 16px;
            font-weight: 300;
            border: 1px #d9d9d9 solid;
          }
        }
      }
    }

    .payment-card,
    .address-card {
      border: 1px #d9d9d9 solid;
    }

    .address-card {
      position: relative;
      box-shadow: none !important;

      &::after {
        content: "";
        width: 90%;
        margin: 0 10px;
        position: absolute;
        bottom: 0;
        left: 10px;
        height: 1px;
        background: #d9d9d9;
      }

      &:first-child {
        border-bottom: none !important;
      }
      &:last-child() {
        border-top: none !important;
      }
    }

    .shipping-method-review {
      .address-card::after {
        display: none;
      }
    }
    a {
      line-height: 140%;
    }
  }

  .Order-review-container {
    padding: 0 !important;
    width: 100% !important;
    .order-review-checkout {
      padding-top: 2em !important;
      padding-left: 0 !important;
    }
  }

  .checkout-action-button-container {
    margin: 0.75em 0;
    padding: 0 15px;
    padding-right: 0;
    max-width: 680px;
    float: right;
  }
 
  p.MuiFormHelperText-root.Mui-error {
      font-size: 12px;
      font-weight: 500;
      margin: 3px 0 0 3px;
  }

  
} 
.OrderConfirmationPage .MuiContainer-maxWidthLg {
  max-width: none;
}

 .MuiAutocomplete-popper {
  font-size: 14px;
  .MuiAutocomplete-option {
    font-size: 16px;
  }
  .MuiAutocomplete-option:hover {
    background-color: #324047;
    color: #fff;
  }

  
}
#OrderConfirmationPage .sidebar.cartReview .sidebar__content {
  padding-right: 20px;
}
#OrderConfirmationPage {
  h3,
  h2 {
    font-family: var(--fmc-font--proxima-nova);
    font-weight: 500;
  }
  @media (max-width: 999px) {
    h2,
    .heading-2,
    p,
    h3 {
      font-family: var(--fmc-font--proxima-nova);
      font-size: 1em;
    }
    h3 {
      font-weight: 500 !important;
    }
    h2 {
      font-weight: 600;
    }
    .main__content .section__header .print-button {
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

 
}

#CheckOutPage, .CheckOutPage {

.checkout-actions button {
  font-family: var(--fmc-font--proxima-nova);
  font-size: 15px;
  font-weight: 500;
  height: 50px;
  margin-left: .8571428571em;
  padding: 20px;
  position: relative;
  transition: all .2s ease-in-out;
  white-space: nowrap;
  width: auto;
}
 button[disabled]{
  /*background: #f0f4f7;*/
  border-radius: 6px !important;
  color: #ffffff;
  border: 2px solid #4d4d4d;
  cursor: default;
}

.MuiAutocomplete-endAdornment {
    button[disabled]{
    border: initial;
    svg {
      font-size: 25px;
      color: #666;
    }
  }
  button  {
    svg {
      font-size: 25px;
    }
  }
}
#payment-form button[disabled] {
  background: initial;
  border-radius: initial;
  color: #fff;
  border: initial;
  cursor: initial;
}
.MuiButton-containedPrimary {
  color: #fff;
  background-color: #324047;
  border-radius: 6px !important;
  border-top: 0;
    border-right: 0;
    border-left: 0;
}
.ford-pass-redemption .MuiButton-containedPrimary {
  padding: 12px 16px;
}
 

.MuiButton-containedPrimary:hover:before {
  border-bottom: 3px solid #f26148;
  bottom: 0;
  content: " ";
  display: block;
  left: 0;
  opacity: 1;
  position: absolute;
  transition: all .2s ease-in-out;
  width: 100%;
}
}