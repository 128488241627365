
.DisclosureText{

text-align: center;
 
    color: #3d3d3d; 
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer; 
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: var(--fmc-font--proxima-nova);
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 400;
    text-transform: uppercase;



}

.container{
    
   // max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
}

.Disclosures__content{
   // width: 100%;
   
    background: #F7F7F7;
    color: #4d4d4d !important;
    font-size: 12px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    overflow-y: auto;
    height: auto;
    max-height: 300px;
    line-height: 16px;
    padding-top: 30px;
    padding-bottom: 30px;
}


@media (max-width:'650px'){
    .AccordSummary{
        width:'650px'!important


    }
}
@media (min-width:'650') and (max-width:'900')
{
    .AccordSummary{
        width:'650px' !important
    }
}
@media (min-width:'900px') and (max-width:'1100px')
{
    .AccordSummary{
        width:'900px' !important

    }
}
@media (min-width:'1100px') and (max-width:'1200px')
{
    .AccordSummary{
        width:'1100px' !important
    }
}
.disclosure_none{
    display:hidden;
}

.disclosures__trigger-area {
    width: 100% !important;
}
.Disclosures__content a:hover {
    color: #f26148;
}
 